// begin
import { getProappinfo, getTopPerMenuListFPc } from "#/api/login";
// end

import Layout from "#/views/layout/Layout";
const _import = require("./_import_" + process.env.NODE_ENV);

export const developmentRouterMap = [
  {
    path: "/",
    component: Layout,
    name: "delivery-bus-pc-huaxi",
    name: "delivery-bus-pc-huaxi",
    children: [
      {
        name: "待审核商品",
        path: "/platformGoodsAuditList",
        component: _import(
          "goodsCenter/goodsManager/goodsAudit/platformGoodsList/platformGoodsAuditList"
        ),
        meta: { title: "待审核商品", icon: "example" },
      },
      {
        name: "用户入驻审核",
        path: "/dealarStoreApplyForPlat",
        component: _import("dealarStoreApplyForPlat/index"),
        meta: { title: "用户入驻审核", icon: "example" },
        name: "用户入驻审核",
        path: "/dealarStoreApplyForPlat",
        component: _import("dealarStoreApplyForPlat/index"),
        meta: { title: "用户入驻审核", icon: "example" },
      },
      {
        name: "待审核商品",
        path: "/platformGoodsAuditList",
        component: _import(
          "goodsCenter/goodsManager/goodsAudit/platformGoodsList/platformGoodsAuditList"
        ),
        meta: { title: "待审核商品", icon: "example" },
      },
      {
        name: "用户入驻审核",
        path: "/dealarStoreApplyForPlat",
        component: _import("dealarStoreApplyForPlat/index"),
        meta: { title: "用户入驻审核", icon: "example" },
      },
      {
        name: "待审核商品",
        path: "/auditGoodsDetails",
        component: _import(
          "goodsCenter/goodsManager/goodsAudit/platformGoodsList/details"
        ),
        meta: { title: "待审核商品详情", icon: "example" },
      },
      // 运营端
      {
        name: "新增商家",
        path: "/addMerchant",
        component: _import("shopManage/merchantManage/addMerchant"),
        meta: { title: "新增商家", icon: "example" },
        name: "新增商家",
        path: "/addMerchant",
        component: _import("shopManage/merchantManage/addMerchant"),
        meta: { title: "新增商家", icon: "example" },
      },
      {
        name: "商家列表",
        path: "/merchantManage",
        component: _import("shopManage/merchantManage/index"),
        meta: { title: "商家列表", icon: "example" },
        name: "商家列表",
        path: "/merchantManage",
        component: _import("shopManage/merchantManage/index"),
        meta: { title: "商家列表", icon: "example" },
      },
      {
        name: "新增商家",
        path: "/addMerchant",
        component: _import("shopManage/merchantManage/addMerchant"),
        meta: { title: "新增商家", icon: "example" },
        name: "新增商家",
        path: "/addMerchant",
        component: _import("shopManage/merchantManage/addMerchant"),
        meta: { title: "新增商家", icon: "example" },
      },
      {
        name: "商家列表",
        path: "/merchantManage",
        component: _import("shopManage/merchantManage/index"),
        meta: { title: "商家列表", icon: "example" },
        name: "商家列表",
        path: "/merchantManage",
        component: _import("shopManage/merchantManage/index"),
        meta: { title: "商家列表", icon: "example" },
      },
      {
        name: "价格渠道设置",
        path: "/disPriceSet",
        component: _import("disChannel/set/disPriceSet"),
        meta: { title: "价格渠道设置", icon: "example" },
        name: "价格渠道设置",
        path: "/disPriceSet",
        component: _import("disChannel/set/disPriceSet"),
        meta: { title: "价格渠道设置", icon: "example" },
      },
      {
        name: "订单抽佣设置",
        path: "/orderCommissionSettings",
        component: _import(
          "settlementCenter/settlementSettings/orderCommissionSettings/index"
        ),
        meta: { title: "价格渠道设置", icon: "example" },
      },

      // 服务费设置 路由 begin
      {
        name: "服务费设置",
        path: "/serviceFeeSettings",
        component: _import(
          "settlementCenter/settlementSettings/serviceFeeSettings/index"
        ),
        meta: { title: "服务费设置", icon: "example" },
      },
      // 服务费设置 路由 end

      {
        name: "交易中心-订单列表",
        path: "/orderListAll",
        component: _import("orderList/index"),
        meta: { title: "价格渠道设置", icon: "example" },
        // name: "交易中心-订单列表",
        // path: "/orderListAll",
        // component: _import("orderList/index"),
        // meta: { title: "价格渠道设置", icon: "example" },
      },
      {
        name: "交易中心-渠道商品设置",
        path: "/disGoodSet",
        component: _import("disChannel/set/disGoodSet"),
        meta: { title: "价格渠道设置", icon: "example" },
        name: "交易中心-渠道商品设置",
        path: "/disGoodSet",
        component: _import("disChannel/set/disGoodSet"),
        meta: { title: "价格渠道设置", icon: "example" },
      },
      {
        name: "商品范围设置",
        path: "/disGoodsRange",
        component: _import("disChannel/set/disGoodsRange"),
        meta: { title: "价格渠道设置", icon: "example" },
        name: "商品范围设置",
        path: "/disGoodsRange",
        component: _import("disChannel/set/disGoodsRange"),
        meta: { title: "价格渠道设置", icon: "example" },
      },
      {
        name: "优惠券发放统计",
        path: "/cashcouponReceiveList",
        component: _import("salesPromotion/cashcouponReceiveList/index"),
        meta: { title: "优惠券发放统计", icon: "example" },
        name: "优惠券发放统计",
        path: "/cashcouponReceiveList",
        component: _import("salesPromotion/cashcouponReceiveList/index"),
        meta: { title: "优惠券发放统计", icon: "example" },
      },
      {
        name: "小程序装修",
        path: "decorate2c",
        component: _import("decorate2c/index"),
        meta: { title: "小程序装修", icon: "example" },
        name: "小程序装修",
        path: "decorate2c",
        component: _import("decorate2c/index"),
        meta: { title: "小程序装修", icon: "example" },
      },
      {
        name: "商家审核",
        path: "examineMerchant",
        component: _import("userCenter/merchantManage/examineMerchant/index"),
        meta: { title: "商家审核", icon: "example" },
        name: "商家审核",
        path: "examineMerchant",
        component: _import("userCenter/merchantManage/examineMerchant/index"),
        meta: { title: "商家审核", icon: "example" },
      },
      {
        name: "订单列表",
        path: "orderList",
        component: _import("orderList/index"),
        meta: { title: "订单列表", icon: "example" },
        // name: "订单列表",
        // path: "orderList",
        // component: _import("orderList/index"),
        // meta: { title: "订单列表", icon: "example" },
      },
      {
        name: "管理员列表",
        path: "administratorlist",
        component: _import("up/operatorlist/index"),
        meta: { title: "管理员列表", icon: "example" },
      },
      {
        name: "结算清单",
        path: "settlementCenters",
        component: _import("settlementCenter/settlementSettings/index"),
        meta: { title: "结算清单", icon: "example" },
        name: "结算清单",
        path: "settlementCenters",
        component: _import("settlementCenter/settlementSettings/index"),
        meta: { title: "结算清单", icon: "example" },
      },
      {
        path: "village",
        component: _import("store/village"),
        meta: { title: "自提点地址", icon: "example" },
        path: "village",
        component: _import("store/village"),
        meta: { title: "自提点地址", icon: "example" },
      },
      {
        path: "addVillage",
        component: _import("store/addVillage"),
        meta: { title: "新增自提点", icon: "example" },
        path: "addVillage",
        component: _import("store/addVillage"),
        meta: { title: "新增自提点", icon: "example" },
      },
      {
        path: "/platReturns",
        component: _import("trading/Returns/platReturns"),
        meta: { title: "平台退单列表", icon: "example" },
        path: "/platReturns",
        component: _import("trading/Returns/platReturns"),
        meta: { title: "平台退单列表", icon: "example" },
      },
      {
        path: "/marketingAudit",
        component: _import("marketingCenter/marketingAudit/index"),
        meta: { title: "营销审核", icon: "example" },
      },
      {
        path: "/addVoucherList",
        component: _import(
          "marketingCenter/mallMarketingManage/voucherList/components/addVoucherList"
        ),
        meta: { title: "优惠券列表", icon: "example" },
      },
      {
        path: "/dragClassifty",
        component: _import("goodsCenter/goodsManager/dragClassifty/index"),
        meta: { title: "前台分类", icon: "example" },
        path: "/dragClassifty",
        component: _import("goodsCenter/goodsManager/dragClassifty/index"),
        meta: { title: "前台分类", icon: "example" },
      },
      {
        path: "/orderAgingSettings",
        component: _import(
          "settlementCenter/settlementSettings/orderAgingSettings/index"
        ),
        meta: { title: "单节点周期设置", icon: "example" },
      },
      {
        path: "/specNew",
        component: _import("productmanage/specNew/specNew"),
        meta: { title: "平台&商品中心商品规格", icon: "example" },
        path: "/specNew",
        component: _import("productmanage/specNew/specNew"),
        meta: { title: "平台&商品中心商品规格", icon: "example" },
      },
      {
        path: "/vdPlatList",
        component: _import("productmanage/vdPlatGoods/vdPlatList"),
        meta: { title: "平台&商家货品列表-查看", icon: "example" },
        path: "/vdPlatList",
        component: _import("productmanage/vdPlatGoods/vdPlatList"),
        meta: { title: "平台&商家货品列表-查看", icon: "example" },
      },
      {
        path: "/editDiscountList",
        component: _import(
          "marketingCenter/mallMarketingManage/discountList/components/editDiscountList"
        ),
        meta: { title: "编辑-满折活动列表", icon: "example" },
      },
      {
        path: "/priceDiscountList",
        component: _import(
          "marketingCenter/mallMarketingManage/priceDiscountList/index"
        ),
        meta: { title: "满减活动列表", icon: "example" },
      },
      {
        path: "/refundYs",
        component: _import("order/refund/indexYs"),
        meta: { title: "退款单列表", icon: "example" },
        path: "/refundYs",
        component: _import("order/refund/indexYs"),
        meta: { title: "退款单列表", icon: "example" },
      },
      {
        path: "/shopBrand",
        component: _import("shopBrand/index"),
        meta: { title: "商品品牌", icon: "example" },
        path: "/shopBrand",
        component: _import("shopBrand/index"),
        meta: { title: "商品品牌", icon: "example" },
      },
      {
        path: "/editPriceDiscountList",
        component: _import(
          "marketingCenter/mallMarketingManage/priceDiscountList/components/editPriceDiscountList"
        ),
        meta: { title: "新增编辑-满减活动列表", icon: "example" },
      },
      {
        path: "/addDiscountList",
        component: _import(
          "marketingCenter/mallMarketingManage/discountList/components/addDiscountList"
        ),
        meta: { title: "新增-满折活动列表", icon: "example" },
      },

      {
        path: "distributorManage",
        component: _import("distributorManage/index"),
        name: "配送员管理",
      },
      {
        path: "memberlist",
        component: _import("member/memberlist/buyerList"),
        name: "采购商列表",
        name: "采购商列表",
      },
      {
        path: "addBuyerzq",
        component: _import("buyerList/addBuyerzq"),
        name: "新增采购商",
      },
      {
        path: "/orderListNew",
        name: "订单列表",
        component: _import("order/listAll/listOrder"),
      },
      // {
      //   path: "/orderListAll",
      //   name: "订单列表（商家端）",
      //   component: _import("orderList/index"),
      // },

      // 商家端
      {
        path: "freight",
        name: "运费模板（商家端）",
        component: _import("freight/freighttem/index"),
      },

      {
        name: "商品列表",
        path: "goodsList",
        component: _import("goodsCenter/goodsManage/goodsList/index"),
        component: _import("goodsCenter/goodsManage/goodsList/index"),
      },
      {
        name: "商品列表-列表查看",
        path: "goodsListDetails",
        component: _import("goodsCenter/goodsManage/goodsList/listDetails"),
        component: _import("goodsCenter/goodsManage/goodsList/listDetails"),
      },
      {
        name: "商品列表-编辑",
        path: "goodsListEdit",
        component: _import("goodsCenter/goodsManage/goodsList/listEdit"),
        component: _import("goodsCenter/goodsManage/goodsList/listEdit"),
      },
      {
        name: "商品列表-详情查看",
        name: "商品列表-详情查看",
        path: "goodsDetails",
        component: _import(
          "goodsCenter/goodsManage/goodsList/goodsDetails/index"
        ),
      },
      // {
      //   path: "/orderListAll",
      //   name: "订单列表（商家端）",
      //   component: _import("orderList/index")
      // },
      // {
      //   path: "/orderListAll",
      //   name: "订单列表（商家端）",
      //   component: _import("orderList/index"),
      // },
      {
        path: "editVoucherList",
        name: "编辑-代金券列表",
        component: _import(
          "marketingCenter/mallMarketingManage/voucherList/components/editVoucherList"
        ),
      },
      {
        path: "editVillage",
        name: "编辑自提点",
        component: _import("store/editVillage"),
      },
      {
        path: "platformGoodsList",
        name: "平台商品列表",
        component: _import("goodsCenter/platformGoodsList/index"),
      },
      {
        path: "productclassify",
        name: "后台分类",
        component: _import("productmanage/productclassify/components/dalei"),
      },
      {
        path: "goodsParams",
        name: "商品参数",
        component: _import("productmanage/params/index"),
      },
      {
        path: "reportCompany",
        name: "企业客户对账报表",
        component: _import("report/reportCompany"),
      },
      {
        path: "reportPersonal",
        name: "个人客户对账报表",
        component: _import("report/reportPersonal"),
      },
      {
        path: "administratorlist",
        name: "管理员列表",
        component: _import("up/operatorlist/index"),
      },
      {
        path: "voucherList",
        name: "优惠券列表",
        component: _import(
          "marketingCenter/mallMarketingManage/voucherList/index"
        ),
      },
      {
        path: "addVoucherList",
        name: "新增-优惠券列表",
        component: _import(
          "marketingCenter/mallMarketingManage/voucherList/components/addVoucherList"
        ),
      },
      {
        path: "editVoucherList",
        name: "编辑-代金券列表",
        component: _import(
          "marketingCenter/mallMarketingManage/voucherList/components/editVoucherList"
        ),
      },
      {
        path: "voucherListDetail",
        name: "查看-代金券列表",
        component: _import(
          "marketingCenter/mallMarketingManage/voucherList/detail"
        ),
      },
      {
        path: "paymentYs",
        name: '付款单列表"',
        component: _import("order/payment/indexYs"),
      },
      {
        path: "property",
        name: '商品属性"',
        component: _import("productmanage/property/index"),
      },
      {
        path: "shopType",
        name: '商品类型"',
        component: _import("productmanage/productclassify/components/shopType"),
      },
      {
        path: "blacklist",
        name: "黑名单列表",
        component: _import("blacklist/index"),
      },
      {
        path: "params",
        name: "商品参数",
        component: _import("productmanage/params/index"),
      },
      {
        path: "information",
        name: "基础配置",
        component: _import("systemManager/information"),
      },
      {
        path: "indexRetail",
        name: "客服配置",
        component: _import("customerConfig/indexRetail"),
      },
      // discountList
      {
        path: "discountList",
        name: "满折列表",
        component: _import("marketingCenter/mallMarketingManage/discountList/index"),
      },
      {
        path: "unit",
        name: "单位配置",
        component: _import("unit/list"),
      },
      {
        path: "reconciliationReport",
        name: "对账报表",
        component: _import("reportFormsCenter/reconciliationReport/index"),
      },
    ],
  },
];
export const productionRouterMap = [
  {
    name: "登录",
    path: "/loginWhite",
    component: _import("login/loginWhite"),
    beforeEnter: (to, from, next) => {
      let sJson = JSON.parse(window.sessionStorage.getItem("proappEnvLayout"));
      if (sJson) {
        next();
      } else {
        getProappinfo().then((data) => {
          if (data) {
            window.sessionStorage.setItem(
              "proappEnvLayout",
              JSON.stringify(data)
            );
            window.sessionStorage.getItem("menuList") ||
              getTopPerMenuListFPc().then((res) => {
                window.sessionStorage.setItem("menuList", JSON.stringify(res));
              });
            getLink(data);
            next();
          }
        });
      }
    },
  },
  {
    name: "忘记密码页面",
    path: "/fgPassword",
    component: _import("loginPc/forgetPwdPc/indexsupplier"),
  },
];

export default new VueRouter({
  scrollBehavior: () => ({
    y: 0,
  }),
  routes:
    process.env.NODE_ENV == "development"
      ? developmentRouterMap
      : productionRouterMap,
});

// begin

function getLink(data) {
  var url =
    "https://qjstatic.oss-cn-shanghai.aliyuncs.com/theme/index.min.409eff.css";
  if (data && data.proappEnvTheme) {
    url =
      "https://qjstatic.oss-cn-shanghai.aliyuncs.com/theme/index.min." +
      data.proappEnvTheme +
      ".css";
  }
  addCssByLink(url);
}

function addCssByLink(url) {
  var doc = document;
  var link = doc.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("type", "text/css");
  link.setAttribute("href", url);

  var heads = doc.getElementsByTagName("head");
  if (heads.length) heads[0].appendChild(link);
  else doc.documentElement.appendChild(link);
}

// end
