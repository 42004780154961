export default {
    /**
     * 满减活动
     */
    // reduction
    // 页面查询列表
    reductionQueryMarketingList: {
        method: 'post',
        url: 'web/reduction/queryMarketingList.json'
    }, 
}