export default {
   loginPc: {
      angang: {
         angangLogin: {
            vLogin: {
               title: 'Членский состав',
               tabTitle: ['пароль на счёт', 'вход в систему'],
               nameLabel: 'счёт',
               namePlace: 'Введите номер счёта',
               pwdLabel: 'пароль',
               pwdPlace: "Введите пароль",
               codeLabel: "код проверки",
               codePlace: "Введите код проверки",
               remPwd: "Запомнить пароль",
               phoneLabel: "+86（материковый китай）",
               phonePlace: "Введите номер телефона",
               msgPlace: "Введите код проверки SMS",
               loginAuto: "автоматический вход в систему через 7 дней",
               loginBtn: "вход",
               forgetLabel: "забыть пароль?",
               noAccount: "Нет номера счета?",
               gotoSign: "зарегистрироваться",
               getMsgCode: "получение кода проверки",
               surplus: "остаток",
               rules: {
                  phoneError: "Введите правильный номер телефона",
                  mailboxError: "Введите правильный почтовый ящик",
                  codeError: "Ошибка ввода кода проверки"
               }
            }
         }
      },
      regPc: {
         regBus: {
            vLogin: {
               sign: 'регистрация',
               signM: ['номер телефона', 'почтовый ящик'],
               enterprise: ['предприятие', 'личный'],
               selected: 'Выберите',
               readAgree: 'Я читал и согласился',
               reset: 'сбросить',
               title: 'Членский состав',
               tabTitle: ['пароль на счёт', 'вход в систему'],
               nameLabel: 'счёт',
               namePlace: 'Введите номер счёта',
               companyNamePlace: 'Введите название компании',
               youNamePlace: 'Введите имя',
               pwdLabel: 'пароль',
               pwdPlace: "Введите пароль",
               pwdsPlace: 'Введите пароль в 6 - 20 битов из числа или буквы ',
               codeLabel: "код проверки",
               codePlace: "Введите код проверки",
               numCodePlace: "Введите 6 - битный код для проверки цифр",
               remPwd: "Запомнить пароль",
               phoneLabel: "+86（материковый китай）",
               phonePlace: "Введите номер телефона",
               msgPlace: "Введите код проверки SMS",
               mailboxPlace: "Введите почтовый ящик",
               loginAuto: "автоматический вход в систему через 7 дней",
               loginBtn: "вход",
               forgetLabel: "забыть пароль?",
               noAccount: "Нет номера счета?",
               gotoSign: "зарегистрироваться",
               atOnceSign: "немедленная регистрация",
               vipSign: '《Соглашение о регистрации участников》',
               existing: 'есть номер счёта?  прямой доступ',
               getMsgCode: "получение кода проверки",
               surplus: "остаток",
               close: 'закрыть',
               agreement: 'Соглашение о членстве',
               none: 'закрыть пока нет информации',
               rules: {
                  phoneError: "Введите правильный номер телефона",
                  mailboxError: "Введите правильный почтовый ящик",
                  codeError: "Ошибка ввода кода проверки"
               },
               check: "Сначала прочитайте протокол",
               messageError: ['успех', 'неудача']
            }
         }
      },
      loginUi: {
         forgetPw: {
            title: "получить пароль",
            enterPhone: "Введите номер телефона или почтовый ящик",
            codeLabel: "код проверки ввода",
            pwdPlace: "Введите новый пароль",
            confirm: 'определение',
            toLogin: "войти",
            pwdError: "Введите новый пароль",
            pwdsPlace: 'Введите новый пароль из числа или буквы ',
            phonePlace: "Введите номер телефона",
            phoneError: "Введите правильный номер телефона",
            phoneNoSign: "номер телефона не зарегистрирован",
            codeError: "Ошибка ввода кода проверки",
            codePlace: "Введите код проверки",
            numCodePlace: "Введите 6 - битный код для проверки цифр",
            codes: "получение кода проверки",
            remain: "остаток",
            s: "секунда",
            verCodeError: "Ошибка ввода кода проверки",
            success: 'успех',
            phoneOremil: 'номер телефона или почтовый ящик',
            error: 'неудача'
         },
         window_login: {
            title: "вход",
            accountPlace: "ввод номера счета",
            pwdPlace: "Введите пароль",
            phoneCodeLogin: "вход в систему",
            login: "вход",
            forgetPwd: "Забыл пароль?",
            toSign: "зарегистрироваться",
            pwdError: "Введите пароль",
            pwdErrorPlace: "Введите пароль в 6 - 20 битов из числа или буквы",
            phoneRules: "ввод номера счета",
            tipPwdError: "ошибка заполнения пароля",
         },
         window_loginCode: {
            title: "регистрация на мобильный",
            enterPhone: "входной номер телефона",
            codeLabel: "код проверки ввода",
            codes: "получение кода проверки",
            login: "вход",
            toSign: "зарегистрироваться",
            accountPwd: "пароль на счёт",
            phonePlace: "Введите номер телефона",
            phoneError: "Введите правильный номер телефона",
            codePlace: "Введите код проверки",
            numCodePlace: "Введите 6 - битный код для проверки цифр",
            remain: "остаток",
            s: "секунда",
         }
      },
      loginBus: {
         wait: {
            wait: 'Ждите проверки',
            mobile: 'служебный телефон',
            home: 'вернуться на главную страницу',
            messageError: ['успех', 'неудача']
         }
      }
   },
   disChannel: {
      order: {
         storeAbnormalOrder: {
            plat: "платформа",
            platPlaceholder: "Введите номер заказа платформы",
            platOrder: "номер заказа платформы",
            promptTitle: 'Уверен, что пересылать данные платформы?'
         },

      }
   },
   car_mod: {
      car: {
         index: {
            carUi: {
               shoppingcar: {
                  thead: ['полное обогащение', 'товарная информация', 'единичная цена', 'количество', 'итог', 'операция'],
                  btnPrompt: 'сходить в список',
                  delete: 'Удалить',
                  selSale: 'избранное продвижение',
                  edit: 'Изменить',
                  reset: 'Обновить',
                  saleList: 'список сбыта',
                  confirm: 'определение',
                  cancel: 'отмена',
                  car: 'торговый автомобиль'
               }
            },
            checkboxAll: 'полное обогащение',
            deletePrompt: 'Удалить выбранный товар',
            goodsPrompt: ['избранный', 'штучный товар', 'Итого (без фрахта)'],
            subtitle: 'немедленный расчёт',
            confirm: 'определение',
            cancel: 'отмена',
            selectNonePrompt: 'Вы еще не выбрали товар',
            confirmPrompt: 'Удалить выбранный товар?',
            alertCont: 'Выберите товар для удаления',
            prompt: 'подсказка',
            successPrompt: 'Удалено успешно',
            stockprompt: ['запасы не могут быть закуплены', 'нельзя купить больше товара', 'количество покупки не может быть меньше заказа', 'не хватает запасов']
         }
      },
      accounts: {
         index: {
            title: "заполнение и представление информации о заказах",
            getGoodsMsg: "Информация о получении",
            newAddress: "использовать новый адрес",
            payWay: "форма платежа",
            onlinePay: "онлайновый платеж",
            Offline: 'платеж по линии',
            goodsList: "перечень товаров",
            useCoupons: "использовать купон",
            selectPlace: "Выберите",
            payIntegral: "платёжный интеграл",
            payIntegralPlace: "введите используемый интеграл",
            goodsNum: "Общее количество мест:",
            goodsMoney: "Общая сумма товаров:",
            goodsFreight: "стоимость товара:",
            couponsMoney: "льготная сумма：",
            memberEquities: "Членство",
            submitOrder: "представлять заказ",
            payable: "Общая сумма кредиторской задолженности:",
            noInventory: "нет запасов",
            noOrder: "вы ещё не заказали",
            addAddress: "Пожалуйста, добавьте адрес получателя",
            giftBagFor: "обмен подарков",
            forSuccess: "успех обмена",
            forFail: "ошибка обмена",
            goOnFor: "продолжать обмен",
            orderResults: "Следующий результат",
            payOrderSuccess: "Следующий успех",
            payOrderFail: "Ошибка при выписке",
            prizeFor: "обмен призов",
            msgError: "условия выдачи ваучеров не соблюдены",
            subTipError: "не существует товаров для покупки автомобилей",
            noAuthentication: "Нет аутентификации"
         },
         account_ui: {
            addAddress: {
               area: "Местонахождение:",
               selectPlace: "Выберите",
               addressDetails: ["подробный адрес:", "подробный адрес, улица, номер дома"],
               consignee: "станция порт назначения:",
               phone: "номер телефона:",
               default: "По умолчанию",
               confirm: 'определение',
               cancel: 'отмена',
               validatePhoneNone: "Введите номер телефона",
               validatePhoneError: "Введите правильный номер телефона",
               validateAddressNone: "Заполните, пожалуйста, подробный адрес.",
               validateNameNone: "Заполните, пожалуйста, грузополучатель.",
               novalidatePhone: "Номер телефона неверен, введите его еще раз",
               subsuccess: "Успешная операция",
               suberror: 'операция не удалась'

            },
            addAddressDt: {
               area: "Местонахождение:",
               selectPlace: "Выберите",
               selectPlaceT: "Введите область",
               addressDetails: ["подробный адрес:", "подробный адрес, улица, номер дома"],
               consignee: "станция порт назначения:",
               phone: "номер телефона:",
               default: "По умолчанию",
               confirm: 'определение',
               cancel: 'отмена',
               validatePhoneNone: "Введите номер телефона",
               validatePhoneError: "Введите правильный номер телефона",
               validateAddressNone: "Заполните, пожалуйста, подробный адрес.",
               validateNameNone: "Заполните, пожалуйста, грузополучатель.",

               novalidatePhone: "Номер телефона неверен, введите его еще раз",
               subsuccess: "Успешная операция",
               suberror: 'операция не удалась'


            },
            accountsItem: {
               goodsMsg: "товарная информация",
               specification: "стандарт",
               price: "цена",
               amount: "количество",
               totle: "итог",
               gifts: "дары",
               courier: "обычный курьер",
               remark: "Примечание:",
            }
         }

      }
   },
   pay_mod: {
      pay: {
         index: {
            submitOrderSuccess: "ваш заказ был успешно представлен, пожалуйста, оплатите как можно скорее ",
            orderCode: "номер заказа:",
            totalMoney: "Общая сумма：",
            changePayMoney: "изменение суммы платежа",
            orderMoney: "Общая сумма заказа:",
            payMoney: "выплаченная сумма：",
            thisPayMoney: "сумма текущего платежа:",
            editPayMoney: "Изменить размер платежа",
            payMoneyPlace: "Введите сумму платежа",
            payWay: "форма платежа",
            baseAccount: "основной счёт",
            accountBalance: "сальдо счета:",
            thisOrderDiscount: "настоящая скидка:",
            otherPayWay: "Другие формы оплаты",
            confirm: "определение",
            cancel: 'отмена',
            inputPayPwd: "Введите пароль для оплаты",
            blessPoint: "счастливое место",
            WeChatPay: "оплата микрокода",
            user: ["счет I", "счет II", "счет III"],
            editPayMsg: "пользовательская сумма не может превышать общую сумму заказа",
            payMsg: "сумма платежа не может быть меньше 0",
            noPayWay: "пока не оплаченная форма",
            payWayPlace: "Выберите режим платежей",
            prompt: "подсказка",
            ensure: "определение",
            surePwdMsg: "длина пароля меньше 6 битов",
            paySuccess: "платёж успешно",
            upSuccess: "передача успешно завершена",
            payFail: "ошибка платежа",
            queryOrder: "просмотреть заказ",
            backHome: "вернуться на главную страницу"
         }
      }
   },
   collectionPc: {
      collectionShop: {
         goods: "товар",
         shop: "магазин",
         batchManagement: "управление партиями",
         allSelect: "полное обогащение",
         delete: 'Удалить',
         cancelManagement: "отменить управление",
         message: ["Выберите магазин, который вы хотите удалить", "Выберите товар, который вы хотите удалить"],
      },
      index: {
         title: 'моя коллекция',
         iconTitle: 'управление партиями',
         selectAll: 'полное обогащение',
         deleteBtn: 'Удалить',
         deletePrompt: "Выберите товар, который вы хотите удалить",
         cancelBtn: 'отменить управление',
         goodsError: {
            '1': 'нехватка запасов',
            '2': 'нижняя рама',
            '3': 'утратить силу'
         },
         nextPageError: 'недоступный маршрут'
      },
      indexA: {
         bidCollect: "коллекция конкурентных цен",
         shopCollect: "магазин",
         goodsCollect: "коллекция товаров",
         batchManagement: "управление партиями",
         allSelect: "полное обогащение",
         delete: 'Удалить',
         cancelManagement: "отменить управление",
         message: ["Выберите пакет, который вы хотите удалить", "Выберите магазин, который вы хотите удалить", "Выберите товар, который вы хотите удалить"],
      },
      history: {
         title: 'мой след',
         iconTitle: 'управление партиями',
         selectAll: 'полное обогащение',
         deleteBtn: 'Удалить',
         deletePrompt: "Выберите товар, который вы хотите удалить",
         cancelBtn: 'отменить управление',
         noHistory: 'Пока нет следов',
         goodsError: {
            '1': 'нехватка запасов',
            '2': 'нижняя рама',
            '3': 'утратить силу'
         },
         nextPageError: 'недоступный маршрут'
      },
      historyRow: {
         title: "моя коллекция",
         goodsName: "наименование товара",
         price: "цена",
         operation: "операция",
         cancelCollect: "отмена коллекции",
         noGoods: "Пока нет товара",
      },

   },
   invoice_pc: {
      myInvoice: {
         myInvo: {
            title: "мой счёт",
            tabTitle: [
               "Информация о счете - фактуре",
               "выписывать чек",
               "повышение стоимости билета",
            ],
            thead: [
               "время выдачи билета",
               "тип счета",
               "тип векселя",
               "наименование векселя",
               "идентификационный номер налогоплательщика",
               "сумма векселя",
               "состояние",
            ],
            userinvType: [
               "общий электронный счёт",
               "накладная на НДС",
               "специальный счёт НДС",
            ],
            userinvSort: ["личный", "единица"],
            prompt: ["Представлено", "выписанный билет", "срыв выдачи билета"],
            edit: "редактор",
            explain:
               "мы закончим ревизию в течение рабочего дня, обратите внимание, что действительный счет НДС капитализируется только за 1",
            companyName: "наименование единицы",
            companyNamePlace: "Введите имя единицы",
            userinvNo: "идентификационный номер налогоплательщика",
            userinvNoPlace: "Введите идентификационный номер налогоплательщика",
            userinvAdd: "адрес выдачи билета",
            userinvAddPlace: "Введите адрес для выдачи билета",
            userinvPhone: "телефон для выдачи билета",
            userinvPhonePlace: "Введите телефон",
            userinvBankname: "депозитный банк",
            userinvBanknamePlace: "Введите запись",
            userinvBankno: "номер счета",
            userinvBanknoPlace: "Введите номер счёта",
            addImg: "Добавить рисунок",
            qualification: "Свидетельство о правах налогоплательщиков",
            userinvUname: "имя получателя",
            userinvUnamePlace: "Введите имя получателя",
            userinvUphone: "кассовый телефон",
            userinvUphonePlace: "Введите мобильный телефон получателя",
            uAddress: "адрес получателя",
            userinvUadd: "подробный адрес",
            placeholder: "Введите адрес",
            confirm: "определение",
            cancel: "отмена",
            qaPrompt: [
               "Ошибка проверки представленных вами дополнительных билетов",
               "Ваш билет еще не проверен, будьте терпеливы",
            ],
            applyBtn: "повторное заявление",
            tabs: ["все", "Представлено", "выписанный билет", "срыв выдачи билета"],
            errorPrompt: [
               "неправильный ввод телефона",
               "неправильно набран номер телефона получателя",
               "Введите правильный идентификационный номер налогоплательщика",
               "номер счёта - Чистое число",
               "Заполните, пожалуйста.",
               "формат изображений для обычных jpg, png, gif",
               "Количество загруженных изображений не должно превышать 5MB",
            ],
         },
      },
      invoiceInfo: {
         Info: {
            title: "дополнительный счёт",
            addTitle: "Дополнительная информация о счете",
            thead: [
               "имя",
               "операция",
               "название компании",
               "идентификационный номер налогоплательщика",
            ],
            edit: "редактор",
            delete: "Удалить",
            diaTitle: "фактура",
            editInfo: "редактирование информации о счете - фактуре",
            tabType: ["личный", "единица"],
            invoiceName: "наименование фактуры",
            companyName: "наименование единицы",
            infoError: ["Заполните, пожалуйста, всю информацию"],
            userinvMemberPlace: "Введите имя",
            userinvNo: "идентификационный номер налогоплательщика",
            userinvNoPlace: "Введите идентификационный номер налогоплательщика",
            userinvNoError: [
               "Заполните, пожалуйста, идентификационный номер налогоплательщика",
               "идентификационный номер налогоплательщика - буква, цифра",
            ],
            userinvUname: "имя получателя",
            userinvUnamePlace: "Введите имя получателя",
            userinvUphone: "кассовый телефон",
            userinvUphonePlace: "Введите мобильный телефон получателя",
            userinvUphoneError: "Введите правильный номер телефона",
            userinvUeamil: "почтовый ящик получателя",
            userinvUeamilPlace: "Введите почтовый ящик получателя",
            userinvUeamilError: "Введите правильный номер почтового ящика",
            address: "адрес получателя",
            placeholder: "Введите пожалуйста",
            addressDetails: "подробный адрес",
            userinvUadd: "Введите адрес",
            confirm: "определение",
            cancel: "отмена",
            diaDeletePrompt: "Подтверждение удаления счета",
            success: "Операция прошла успешно",
            error: "Ошибка операции",
         },
      },
      issueInvoice: {
         issue: {
            title: "выписывать чек",
            orderNo: "номер заказа",
            payName: "платеж в натуре",
            wInvoice: "выписывать чек",
            diaTitle: "фактура",
            invoicePrompt:
               "Электронные обычные счета имеют одинаковую юридическую силу с обычными счетами,Вспомогательные счета",
            invoiceNotice: "инструкция по фактуре",
            invoiceTypeTitle: "тип счета",
            invoiceType: [
               "общий электронный счёт",
               "накладная на НДС",
               "специальный счёт НДС",
            ],
            invoiceTitle: "фактура",
            tabType: ["личный", "единица"],
            invoiceName: "наименование векселя",
            invoiceNamePlace: "Введите имя",
            placeholderSelect: "Выберите",
            selectHave: "выбрать уже",
            userinvUname: "имя получателя",
            userinvUnamePlace: "Введите имя получателя",
            userinvUphone: "кассовый телефон",
            userinvUphonePlace: "Введите мобильный телефон получателя",
            userinvMember: "наименование единицы",
            userinvMemberPlace: "Введите имя единицы",
            address: "адрес получателя",
            userinvUadd: "Введите адрес",
            userinvAdd: "адрес выдачи билета",
            userinvAddPlace: "Введите адрес для выдачи билета",
            userinvNo: "идентификационный номер налогоплательщика",
            userinvNoPlace: "Введите идентификационный номер налогоплательщика",
            userinvPhone: "телефон для выдачи билета",
            userinvPhonePlace: "Введите телефон",
            userinvBankname: "депозитный банк",
            userinvBanknamePlace: "Введите запись",
            userinvBankno: "номер счета",
            userinvBanknoPlace: "Введите номер счёта",
            addImg: "Добавить рисунок",
            userinvUEamilRules: "Введите почтовый ящик получателя",
            userinvUTrueEamilRules: "Введите правильный формат почтового ящика",
            userinvUnameRules: "Введите имя получателя",
            userinvUphoneRules: "Введите правильный номер телефона",
            userinvUaddRules: "Введите адрес получателя",
            userinvNoRules: "Введите идентификационный номер налогоплательщика",
            confirm: "определение",
            cancel: "отмена",
            errorPrompt: [
               "Заполните, пожалуйста.",
               "Введите правильный идентификационный номер налогоплательщика",
               "Заполните правильный номер телефона.",
               "Заполните, пожалуйста, подробный адрес.",
               "Заполните правильно открытый телефон",
            ],
            imgError: [
               "Снимок загружен максимум на один",
               "формат изображений для обычных jpg, png, gif",
               "Количество загруженных изображений не должно превышать 5MB!",
            ],
         },
      },
   },
   pc: {
      evaluate: {
         myComment: {
            title: 'моя оценка',
            headerTab: ['товар', 'оценка'],
            score: 'балл'
         },
         Comment: {
            title: 'комментировать товар',
            subbutton: 'представление',
            failed: 'ошибка комментариев',
            message: 'успешно комментировать',
            evPrompt: 'звезда магазина оценка должна быть полной',
            imglimitSize: 'Количество загруженных изображений не должно превышать 5MB!',
            imgFormat: 'формат рисункаjpg、png、gif формат'
         },
         myConsult: {
            customerService: 'принимать гостей',
            noReply: 'Пока нет ответа '
         }
      },
      order: {
         index: {
            orderNoPlace: 'Введите номер заказа',
            search: 'запрос',
            confirm: 'определение',
            cancel: 'отмена',
            userName: 'имя',
            userNamePlace: 'Введите имя',
            userCard: 'номер удостоверения личности',
            userCardPlace: 'Введите номер удостоверения личности',
            address: 'район:',
            placeholderSelect: 'Выберите',
            addressInfo: 'подробный адрес:',
            addressInfoPlace: 'подробный адрес, улица, номер дома',
            email: 'почтовый ящик',
            emailPlace: "Введите почтовый ящик",
            fax: 'телефакс',
            faxPlace: 'Введите факс',
            tips: 'Примечания：после того, как имя будет заполнено и подтверждено без изменений, проверьте внимательно',
            phoneError: ['Введите правильный номер телефона', 'Введите номер телефона'],
            cardError: ['Введите правильный идентификационный номер', 'Введите номер удостоверения личности'],
            nav: 'мой заказ',
            rules: ['Выберите провинции', 'Выберите город', 'Выберите область', 'Заполните, пожалуйста, подробный адрес'],
            certificationTitle: 'Улучшение информации',
            tabTitle: ['все', 'подлежать оплате', 'подлежать отгрузке', 'ожидающий получения', 'закончено', 'отменено', 'после продажи'],
            refundState: {
               "0": "подавать заявку успешно，ждать проверки продавца",
               "1": "рассмотреть и утвердить",
               "2": "при отправке покупателем...",
               "3": "продавец получает товар",
               "4": "возврат",
               "5": "Продавец отказался от заявки",
               "6": "Продавец отказался от товара",
               "7": "возврат денежных средств завершен",
               "-1": "аннулировать заявку на возмещение",
               "8": "возврат денежных средств завершен"
            },
            msgPrompt: ['подтверждение получения', 'Подтверждение отмены заказа', 'Пока нет курьерской информации', 'контракт подписан']

         },
         order_ui: {
            orderItem: {
               order: "заказ",
               name: "грузополучатель",
               orderState: "состояние заказа",
               realPay: "уплаченная сумма",
               operation: "операция",
               orderDetails: "Подробности заказа",
               refund: "возвратная сумма",
               serviceType: "Тип службы",
               processState: "режим обработки",
               orderNum: "номер заказа: ",
               yetOrder: "список",
               atOncePay: "немедленная оплата",
               cancelOrder: 'аннулировать заказ',
               signedContract: "подписать контракт",
               viewContract: "контракт",
               viewProgress: "просмотреть ход",
               viewOrder: "просмотреть заказ",
               confirmGoods: "подтверждать получение",
               viewLogistics: "просмотреть логистику",
               evaluation: ["оценка", "оценк"],
               refundNum: "номер возврата:",
               orderTime: "Следующее время: ",
               returnRefund: "возврат товара",
               onlyRefund: "Возврат только",
               viewDetails: "смотреть подробности",
               noOrder: "Пока нет соответствующего заказа",
               orderType: {
                  "00": "натуральный заказ",
                  "04": "заказ на проект",
                  "05": "покупка",
                  "06": "интегральный заказ",
                  "07": "виртуальный заказ",
                  "08": "заказ на обмен пакетов",
                  "09": "заказ на право",
                  "10": "заказ на обмен призов",
                  "11": "заказ на обслуживание",
                  "12": "заказ на карточку"
               },
               dataState: ["отменено", "инициализация", "подлежать оплате", "Приготовиться", "Ждем товар", "успешн"],

            }
         },
         orderInfor: {
            orderNo: "номер заказа:",
            cancelOrder: "аннулировать заказ",
            atOncePay: "немедленная оплата",
            viewLogistics: "просмотреть логистику",
            confirmGoods: "подтверждать получение",
            payDown: ['платеж по линии', 'онлайновый платеж'],
            toEvaluate: "перейти к оценке",
            yetCancel: "отменено",
            buyerOrder: "покупатель выставил счет",
            waitSeller: "ждать отправки от продавца",
            sellerWait: "продавец отгрузил，ожидание подтверждения приемки",
            complete: "завершение операции",
            waitEvaluate: "завершение операции，подлежать оценке",
            payFailer: "ошибка платежа",
            paySuccess: "платёж успешно",
            address: "адрес получения",
            orderTime: "Следующее время",
            payWay: "форма платежа",
            onlinePay: "онлайновый платеж",
            logisticsMsg: "логистическая информация",
            No: "Пока нет",
            getGoodsMsg: "Информация о получении",
            payTime: "время платежа",
            goodsMsg: "товарная информация",
            goodsName: "наименование товара",
            price: "цена",
            quantity: "количество",
            discountAmount: "льготная сумма",
            totle: "итог",
            operation: "операция",
            refund: "возврат денежных средств",
            afterSales: "после продажи",
            goodsTotalAmount: "Общая сумма товара:",
            freight: "фрахт",
            memberEquity: "Членство",
            Payable: "Кредиторская задолженность(включая фрахт)",
            realPay: "платеж в натуре(включая фрахт)",
            noNeedPay: "без оплаты",
            confirm: 'определение',
            cancel: 'отмена',
            confirmGetGoods: "подтверждать получение",
            confirmCancelOrder: "Подтверждение отмены заказа",
            noExpressMsg: "Пока нет курьерской информации"
         }
      },
      coupon: {
         couponList: {
            couponTab: ['Доступные купоны', 'уже используется', 'просрочен'],
            couponUi: {
               unit: ['юань', 'уменьшать', 'ломать'],
               dateTitle: 'срок действия:',
               couponNone: 'Пока нет ваучеров'
            },

         }
      }
   },
   order: {
      storderList: {
         plat: "платформа",
         platPlaceholder: "Введите номер заказа платформы",
         platOrder: "номер заказа платформы"
      },
      storeOrderList: {
         plat: "платформа",
         erpName: "вход",
         platPlaceholder: "Введите номер заказа платформы",
         platSltPlaceholder: "Выберите платформу",
         platOrder: "номер заказа платформы",
         erpCode: "входной номер",
         erpDate: "время входа",
         erpAmt: "входная сумма",
      },
      apply: {
         num: 'количеств',
         refundMoney: 'Сумма возмещения',
         refundEx: "Заполните заявление об отказе",
         leixing: "тип",
         refundMon: "Только возврат.",
         refundMonAndthing: "Возврат",
         exchangeEx: "Оправдание.",
         placeholder: "пожалуйста, выберите",
         memo: "Опишите возникшую проблему и подробно объясните причину возврата.",
         uploadPic: "Загрузи фото.",
         detailMeo: "Подробное описание",
         submit: "Подать заявление",
         cancel: "отмен",

      }
   },
   integralPc: {
      index: {
         title: "Доступные интегралы",
         thead: ['источник/назначение ', 'интегральное изменение', 'дата'],
         nonePrompt: "временный интеграл!"
      }
   },
   message: {
      list: {
         title: 'Системные уведомления',
         dataNone: 'Пока нет',
         viewDetails: "смотреть подробности"
      }
   },
   userPc: {
      certification: {
         title: "сведения об аутентификации",
         state: 'состояние:',
         type: "тип:",
         realName: "настоящее имя:",
         inArea: ["район", "район："],
         detailedAddress: ['подробный адрес ：', 'подробный адрес, улица, номер дома '],
         legalName: "имя и фамилия юридического лица:",
         IdNumber: "номер удостоверения личности:",
         IdPicture: "фотография с удостоверением личности:",
         positive: 'лицевая сторона',
         reverse: "обратная сторона ",
         businessLicense: 'деловое свидетельство:',
         ensure: "подтверждение",
         cancel: "отмена",
         validateIdPictureNone: "лицевая и обратная сторона удостоверения личности не загружена",
         validatePositiveError: "лицевая сторона удостоверения личности не загружена ",
         validateReverseError: "обратная сторона удостоверения личности не загружена ",
         validateAddressNone: "Заполните, пожалуйста, подробный адрес",
         validateNameNone: "Заполните, пожалуйста, грузополучатель",
         dataState: ["Аутентификация", "Зарегистрироваться для проверки", "Неподтверждение", "проверка подлинности", "Ошибка аутентификации"],
         userinfoType: {
            "1": "личный",
            "2": "компания"
         },
         companyAddress: "адрес не может быть пустым",
         userinfoCorp: "имя и фамилия юридического лица",
         userinfoCoid: ["номер удостоверения личности не может быть пустым", "неправильный формат номера удостоверения личности"],
         userinfoCertUrl: "требуется разрешение на загрузку",
         picError: "формат изображений для обычных jpg, png, gif！",
         picError2: "Количество загруженных изображений не должно превышать 5MB!",
         submitSuccess: 'Сообщение успешно представлено',
      },
      certificationDt: {
         title: "сведения об аутентификации",
         selected: 'Выберите',
         placeholder: "Введите страну",
         placeholderT: "Введите область",
         state: 'состояние:',
         type: "тип:",
         realName: "настоящее имя:",
         inArea: ["район", "район："],
         detailedAddress: ['подробный адрес ：', 'подробный адрес, улица, номер дома '],
         legalName: "имя и фамилия юридического лица:",
         IdNumber: "номер удостоверения личности:",
         IdPicture: "фотография с удостоверением личности:",
         positive: 'лицевая сторона',
         reverse: "обратная сторона ",
         businessLicense: 'деловое свидетельство:',
         ensure: "подтверждение",
         cancel: "отмена",
         validateIdPictureNone: "лицевая и обратная сторона удостоверения личности не загружена",
         validatePositiveError: "лицевая сторона удостоверения личности не загружена ",
         validateReverseError: "обратная сторона удостоверения личности не загружена ",
         validateAddressNone: "Заполните, пожалуйста, подробный адрес",
         validateNameNone: "Заполните, пожалуйста, грузополучатель",
         dataState: ["Аутентификация", "Зарегистрироваться для проверки", "Неподтверждение", "проверка подлинности", "Ошибка аутентификации"],
         userinfoType: {
            "1": "личный",
            "2": "компания"
         },
         companyAddress: "адрес не может быть пустым",
         userinfoCorp: "имя и фамилия юридического лица",
         userinfoCoid: ["номер удостоверения личности не может быть пустым", "неправильный формат номера удостоверения личности"],
         userinfoCertUrl: "требуется разрешение на загрузку",
         areaCode: 'Выберите адрес',
         picError: "формат изображений для обычных jpg, png, gif！",
         picError2: "Количество загруженных изображений не должно превышать 5MB!",
         submitSuccess: 'Сообщение успешно представлено',
      },
      modules: {
         auctionAng: {
            title: "Моя цена",
            seeBid: "все цены",
            bidNotice: "название объявления конкурса",
            bidTime: "конкурентное время",
            margin: "гарантийный фонд:",
            bidState: "конкурентное состояние:",
            operation: "операция",
            session: "номер поля：",
            winBid: "выигравший торг",
            loseBid: "промах",
            note: ["Примечание：", "Примечание：после загрузки платформа должна быть проверена и принята, чтобы участвовать в конкурсе"],
            toPay: "платить",
            toOffer: "Перейти на цену",
            viewDetails: "смотреть подробности",
            resubmit: "повторное представление",
            resubmitAptitude: "пересылать капитал",
            uploadAptitude: "требуемый уровень загрузки：",
            aptitudePlace: "Пожалуйста, загрузите данные",
            confirm: "определение",
            cancel: 'отмена',
            state: ['подлежать поручительству', 'подлежать рассмотрению', 'рассмотреть и утвердить', 'в торгах', 'конец'],
            uploadPicError: ["формат изображений для обычных jpg, png, gif！", "Количество загруженных изображений не должно превышать 2MB!"],
         },
         collection: {
            title: "моя коллекция",
            all: "все >",
            message: ["недоступный маршрут", "уже первая статья！", "Больше нет！"],
         },
         collectionAng: {
            title: "моя коллекция",
            allCollect: "Все коллекции >",
            bidCollect: 'коллекция конкурентных цен',
            storeCollect: "магазин",
            goodsCollect: "коллекция товаров",
            dataState: ["в процессе", "ожидать начала", "конец"],
            margin: "гарантийный фонд:",
            noCollect: "Пока нет коллекции",
            message: "недоступный маршрут",
         },
         header: {
            member: "член",
            changeProfile: "Изменить личные данные",
            lists: ["подлежать оплате", "подлежать отгрузке", "закончено", "отменено", "возвращать товар"],
         },
         headerAng: {
            upSeller: "Преобразовать в продавца",
            changePwd: "Изменить пароль",
            userIntegral: "интеграл пользователя：",
            myAccount: "мой счёт",
            myMargin: "мой гарантийный фонд：",
            viewDetails: "проверять спецификацию",
            allMargin: "полный залог：",
            freezeMargin: "замороженный депозит：",
            topUp: "наполнение",
            withdrawal: "снят",
            myMessage: "мои сообщения",
            systemMsg: "системное сообщение",
            consulting: "мой совет",
            toSee: "пойти посмотреть>",
            member: ["член", "членLV"],

         },
         headerUcc: {
            member: "член",
            changeProfile: "Изменить личные данные",
            lists: ["подлежать оплате", "подлежать отгрузке", "ожидающий получения", "подлежать оценке"],
         },
         order: {
            title: "моя коллекция",
            all: "все",
            orderCode: "номер заказа",
            name: 'Получатель',
            orderState: "состояние заказа",
            money: "Общая сумма",
            operation: "операция",
            integral: "интеграл",
            dataState: ["аудит", "подлежать оплате", "подлежать отгрузке", "отправлять товары", "закончено", "успешная сделка", "отменено"],
            viewDetails: "смотреть подробности",
            atOncePay: "немедленная оплата",
            cancelOrder: 'аннулировать заказ',
            yetCancel: "отменено",
            confirmGoods: "подтвердить",
            viewLogistics: "просмотреть логистику",
            evaluation: ["Оценена", "оценк"],
            noOrder: "Пока нет заказа",
            confirm: "определение",
            cancel: 'отмена',
            msgConfirmGoods: "Товар получен",
            msgCancelOrder: "Подтверждение отмены заказа",
            msgError: "Пока нет курьерской информации",
         },
         orderUcc: {
            title: "моя коллекция",
            all: "все",
            orderCode: "номер заказа：",
            name: 'Получатель',
            orderState: "состояние заказа",
            money: "Общая сумма",
            operation: "операция",
            integral: "интеграл",
            dataState: ["аудит", "подлежать оплате", "подлежать отгрузке", "ожидающий получения", "已完成", "交易成功", "отменено"],
            viewDetails: "смотреть подробности",
            atOncePay: "немедленная оплата",
            cancelOrder: 'аннулировать заказ',
            yetCancel: "отменено",
            confirmGoods: "подтверждать получение",
            viewLogistics: "просмотреть логистику",
            evaluation: ["Оценена", "оценк"],
            noOrder: "Пока нет заказа",
            confirm: "определение",
            cancel: 'отмена',
            msgConfirmGoods: "подтверждать получение",
            msgCancelOrder: "Подтверждение отмены заказа",
            msgError: "Пока нет курьерской информации",
         }
      }
   },

}