const vote = {
  state: {
    datas:{},
    RowsData:{},
    Doclist:{}
  },
  mutations: {
    getFormData:(state,obj)=>{
      state.datas=obj;
    },
    getRowData:(state,obj)=>{
      state.RowsData=obj;
    },
    getDoclist:(state,obj)=>{
      state.Doclist=obj;
    },
  },
  actions: {
    
  }
}


export default vote
