import menuListApi from '@/api/cms/menuListApi.js'
const menuListStore = {
    state: {
        MenuData: [],
        siteList:[],
    },
    mutations: {
        SET_MenuData: (state, data) => {
            state.MenuData = data
        },
        SET_siteList: (state, data) => {
            state.siteList = data
        },
    },
    actions: {
        queryTginfoMenuTree:({ commit, state }, obj) => { 
            return menuListApi.queryTginfoMenuTree(obj || {}).then(res => { 
                if (res) { 
                    // commit('SET_MenuTreeData',res.list)
                    return res;
                }
            })
        },
        getTginfoPage: ({ commit, state }, obj) => {
            return menuListApi.queryTginfoPage(obj || {}).then(res => {
                if (res) {
                    commit('SET_siteList', res.list)
                    return res;
                }
            })
        },
        getPfsModelPage: ({ commit, state }, obj) => {
            return menuListApi.queryPfsModelPage(obj||{}).then(res => {
                if (res) {
                    return res;
                }
            })
        },
        getTginfoMenuPage: ({ commit, state }, obj) => {
            return menuListApi.queryTginfoMenuPage(obj || {}).then(res => {
                if (res) {
                    commit('SET_MenuData',res.list)
                    return res;
                }
            })
        },
        delTginfoMenu: ({ commit, state }, obj) => {
            return menuListApi.deleteTginfoMenu(obj || {}).then(res => {
                if (res) {
                    return res;
                }
            })
        },
    }
}

export default menuListStore;