export default {
    /**
     * 锁价页面的请求
     */
    findLockProductList: { // 锁定商品列表查询接口
        method: "get",
        url: "/web/lp/product/findLockProductList.json",
        type: 'xxx-form'
    },
    uploadDeleteAddLockProductExcel: { //批量删除锁定商品接口
         method: "post",
         url: "/web/lp/product/uploadDeleteAddLockProductExcel.json"
    },
    uploadBatchAddLockProductExcel: { // 批量修改新增锁定商品接口
        method: "post",
        url: "/web/lp/product/uploadBatchAddLockProductExcel.json"
    },
    deleteLockProduct: { //删除锁定商品接口
        method: "post",
        url: "/web/lp/product/deleteLockProduct.json"
    },
    addOrUpdateLockProduct: { // 新增修改锁定商品接口
        method: "post",
        url: "/web/lp/product/addOrUpdateLockProduct.json"
    },
    findBatchFileUpload: { // 查询锁价的上传任务列表
        method: "post",
        url: "/web/lp/product/findBatchFileUploadList.json"
    },
    downFileUrl: {  // 模版链接下载
        method: "get",
        url: "/web/fm/file/downFileUrl.json",
        type: 'xxx-form',
        white: true
    },
    downLockGoodsTemplateStream: {  // 批量模版链接下载
        method: "post",
        url: "/web/lp/product/downLockGoodsTemplateStream.json",
        responseType: 'blob'
    },
    
}