export default {
   loginPc: {
      angang: {
         angangLogin: {
            vLogin: {
               title: 'Member sign in',
               tabTitle: ['Account login', 'Verification code login'],
               nameLabel: 'Account',
               namePlace: 'Please enter account number',
               pwdLabel: 'Password',
               pwdPlace: "Please enter password",
               codeLabel: "Verification code",
               codePlace: "Please enter verification code",
               remPwd: "Remember the password",
               phoneLabel: "+86（The Chinese mainland）",
               phonePlace: "Please enter your mobile phone number",
               msgPlace: "Please enter SMS verification code",
               loginAuto: "Automatic sign in within 7 days",
               loginBtn: "sign in",
               forgetLabel: "Forget your password?",
               noAccount: "No account?",
               gotoSign: "Sign up",
               getMsgCode: "Get",
               surplus: "Surplus",

               rules: {
                  phoneError: "Please enter the correct mobile phone number",
                  codeError: "Verification code input error"
               }
            }
         }
      },
      regPc: {
         regBus: {
            vLogin: {
               sign: 'Sign up',
               signM: ['Phone number', 'Mailbox'],
               enterprise: ['Enterprise', 'Personal'],
               selected: 'Please select a',
               readAgree: 'I have read and agree',
               haves: 'Phone number already exists',
               reset: 'Reset',
               title: 'Member sign in',
               tabTitle: ['Account login', 'Verification code login'],
               nameLabel: 'Account',
               namePlace: 'Please enter account number',
               companyNamePlace: 'Please enter the company name',
               youNamePlace: 'Please enter name',
               pwdLabel: 'Password',
               pwdPlace: "Please enter password",
               pwdsPlace: 'Please enter 6-20 numbers or letters',
               codeLabel: "Verification code",
               codePlace: "Please enter verification code",
               numCodePlace: "Please enter a 6-digit verification code",
               remPwd: "Remember the password",
               phoneLabel: "+86（The Chinese mainland）",
               phonePlace: "Please enter your mobile phone number",
               mailboxPlace: "Please enter your mailbox",
               msgPlace: "Please enter SMS verification code",
               loginAuto: "Automatic sign in within 7 days",
               loginBtn: "sign in",
               forgetLabel: "Forget your password?",
               noAccount: "No account?",
               gotoSign: "Sign up",
               atOnceSign: "Register now",
               vipSign: '《Member registration agreement》',
               existing: 'Already have an account? Directly sign in',
               getMsgCode: "Get",
               surplus: "Surplus",
               close: 'close',
               agreement: 'Member registration agreement',
               none: 'none',
               rules: {
                  phoneError: "Please enter the correct mobile phone number",
                  mailboxError: "Please enter the correct mailbox",
                  codeError: "Verification code input error"
               },
               check: "Please read the membership agreement first",
               messageError: ['success', 'error']
            }
         }
      },
      loginUi: {
         forgetPw: {
            title: "Retrieve password",
            enterPhone: "Phone or Mailbox",
            codeLabel: "Verification code",
            pwdPlace: "New password",
            confirm: 'Confirm',
            toLogin: "sign in",
            pwdError: "Please enter new password",
            pwdsPlace: 'Please enter 6-20 numbers or letters',
            phonePlace: "Please enter your Phone or Mailbox",
            phoneError: "Please enter the correct Phone or Mailbox",
            phoneNoSign: "Mobile phone number not registered",
            codeError: "Incorrect captcha input",
            codePlace: "Please enter verification code",
            numCodePlace: "Please enter a 6-digit verification code",
            codes: "Get",
            remain: "Surplus",
            s: "seconds",
            verCodeError: "Verification code input error",
            success: 'success',
            phoneOremil: 'phone Or email',
            error: 'error'
         },
         window_login: {
            title: "Sign in",
            accountPlace: "Account",
            pwdPlace: "password",
            phoneCodeLogin: "Mobile phone verification code sign in",
            login: "sign in",
            forgetPwd: "Forget your password?",
            toSign: "Sign up",
            pwdError: "Please enter password",
            pwdErrorPlace: "Please enter 6-20 numbers or letters",
            phoneRules: "Account",
            tipPwdError: "Your account or password is incorrect",
         },
         window_loginCode: {
            title: "Mobile phone sign in",
            enterPhone: "Enter your mobile phone number",
            codeLabel: "Input verification code",
            codes: "Get",
            login: "sign in",
            toSign: "Sign up",
            accountPwd: "Log in with account and password",
            phonePlace: "Please enter your mobile phone number",
            phoneError: "Please enter the correct mobile phone number",
            codePlace: "Please enter verification code",
            numCodePlace: "Please enter a 6-digit verification code",
            remain: "Surplus",
            s: "seconds",
         }
      },
      loginBus: {
         wait: {
            wait: 'Waiting',
            mobile: 'Mobile',
            home: 'backHome',
            messageError: ['success', 'error']
         }
      }
   },
   car_mod: {
      car: {
         index: {
            carUi: {
               shoppingcar: {
                  thead: ['Check all', 'Goods information', 'Price', 'Number', 'Subtotal', 'Operations'],
                  btnPrompt: 'Additional order',
                  delete: 'Delete',
                  selSale: 'The selected promotion',
                  edit: 'Modify',
                  reset: 'Reset',
                  saleList: 'The promotion list',
                  confirm: "Confirm",
                  cancel: "Cancel",
                  car: 'The shopping cart'
               }
            },
            checkboxAll: 'Check all',
            deletePrompt: 'Confirm Deletion of Selection Goods',
            goodsPrompt: ['checked', 'Goods', ' Total(exclude freight)'],
            subtitle: 'Buy',
            confirm: "Confirm",
            cancel: "Cancel",
            selectNonePrompt: 'You have not selected goods',
            confirmPrompt: 'Are you sure to delete the selected goods?',
            alertCont: 'Please select goods to delete',
            prompt: 'Prompt',
            successPrompt: 'Delete the success',
            stockprompt: ['The stock is less than the minimum order quantity', 'The purchase quantity cannot exceed the inventory of the goods', 'The purchase quantity must not be less than the minimum order quantity', 'Not enough inventory to buy']
         }
      },

      accounts: {
         index: {
            title: "Fill in and submit order information",
            getGoodsMsg: "Goods receiving information",
            newAddress: "Use new address",
            payWay: "Method of payment",
            onlinePay: "Online payment",
            Offline: "Offline payment",
            goodsList: "Goods detailed list",
            useCoupons: "Use coupons",
            selectPlace: "Please select a",
            payIntegral: "Pay the integral",
            payIntegralPlace: "Please enter the credits used",
            goodsNum: "Total number of goods：",
            goodsMoney: "Total amount of goods：",
            goodsFreight: "Goods freight：",
            couponsMoney: "Coupons money：",
            memberEquities: "Rights and interests of members",
            submitOrder: "Submit",
            payable: "The total amount due：",
            noInventory: "No inventory",
            noOrder: "You have no order yet",
            addAddress: "Please add the shipping address",
            giftBagFor: "Redeem gift",
            forSuccess: "Redeem successful",
            forFail: "Redeem failed",
            goOnFor: "Continue to redeem",
            orderResults: "Place the order results",
            payOrderSuccess: "Place an order successfully",
            payOrderFail: "Order failed",
            prizeFor: "Redeem prize",
            msgError: "The coupons does not meet the conditions",
            subTipError: "The products in the cart do not exist or out of stock",
            noAuthentication: 'Please update authentication information in menber center, and wait for certification.'
         },
         account_ui: {
            addAddress: {
               area: "Your area：",
               selectPlace: "Please select a",
               addressDetails: ["Detailed address：", "detailed address, street, house number, etc"],
               consignee: "The consignee：",
               phone: "Mobile phone number：",
               default: "Set as default",
               confirm: "Confirm",
               cancel: 'Cancel',
               validatePhoneNone: "Please enter your mobile phone number",
               validatePhoneError: "Please enter the correct mobile phone number",
               validateAddressNone: "Please fill in the detailed address",
               validateNameNone: "Please fill in the consignee",
               novalidatePhone: "The phone number is incorrect, please re-enter",
               subsuccess: 'Successful operation',
               suberror: 'operation failed'
            },
            addAddressDt: {
               area: "Your area：",
               selectPlace: "Please enter your country",
               selectPlaceT: "Please enter your state",
               addressDetails: ["Detailed address：", "detailed address, street, house number, etc"],
               consignee: "The consignee：",
               phone: "Mobile phone number：",
               default: "Set as default",
               confirm: "Confirm",
               cancel: 'Cancel',
               validatePhoneNone: "Please enter your mobile phone number",
               validatePhoneError: "Please enter the correct mobile phone number",
               validateAddressNone: "Please fill in the detailed address",
               validateNameNone: "Please fill in the consignee",

               novalidatePhone: "The phone number is incorrect, please re-enter",
               subsuccess: 'Successful operation',
               suberror: 'operation failed'
            },
            accountsItem: {
               goodsMsg: "Goods information",
               specification: "specifications",
               price: "Price",
               amount: "Amount",
               totle: "Total",
               gifts: "Gifts",
               courier: "Ordinary courier",
               remark: "Remark：",
            }
         }
      }
   },
   pay_mod: {
      pay: {
         index: {
            submitOrderSuccess: "Your order has been successfully submitted, please pay as soon as possible",
            orderCode: "Order number：",
            totalMoney: "Total amount：",
            changePayMoney: "Modify payment amount",
            orderMoney: "Total order amount：",
            payMoney: "Amount paid: ",
            thisPayMoney: "Amount of this payment: ",
            editPayMoney: "Customizable edit payment amount",
            payMoneyPlace: "Enter the amount of this payment",
            payWay: "Method of payment",
            baseAccount: "Basic account",
            accountBalance: "The account balance：",
            thisOrderDiscount: "Single deduction：",
            otherPayWay: "Other means of payment",
            confirm: "Confirm",
            cancel: "Cancel",
            inputPayPwd: "Please enter the payment password",
            blessPoint: "福点",
            WeChatPay: "WeChat scan code payment",
            user: ["Account 1", "Account 2", "Account 3"],
            editPayMsg: "The custom amount cannot be greater than the total amount of the order",
            payMsg: "The amount paid must not be less than or equal to 0",
            noPayWay: "No payment method is available",
            payWayPlace: "Please select payment method",
            prompt: "Prompt",
            ensure: "Confirm",
            surePwdMsg: "Password length less than 6 digits",
            paySuccess: "Order placed , Please transfer asap",
            upSuccess: "Submit successfully",
            payFail: "paid Failed",
            queryOrder: "View order",
            backHome: "Homepage"
         }
      }
   },
   collectionPc: {
      collectionShop: {
         goods: "Goods",
         shop: "Store",
         batchManagement: "The batch management",
         allSelect: "allSelect",
         delete: 'Delete',
         cancelManagement: "Cancel the management",
         message: ["Please select the store you want to delete", "Please select the goods you want to delete"],
      },
      index: {
         title: 'My collection',
         iconTitle: 'Batch Management',
         selectAll: 'Check all',
         deleteBtn: 'Delete',
         deletePrompt: "Please select the goods you want to delete",
         cancelBtn: 'Cancel the management',
         goodsError: {
            '1': 'Insufficient inventory',
            '2': 'Off',
            '3': 'Failure'
         },
         nextPageError: 'No path obtained'
      },
      indexA: {
         bidCollect: "Auction collection",
         shopCollect: "Store collection",
         goodsCollect: "Goods collection",
         batchManagement: "The batch management",
         allSelect: "allSelect",
         delete: 'Delete',
         cancelManagement: "Cancel the management",
         message: ["Please select the package you want to delete", "Please select the store you want to delete", "Please select the goods you want to delete"],
      },
      history: {
         title: 'My footprint',
         iconTitle: 'Batch Management',
         selectAll: 'Check all',
         deleteBtn: 'Delete',
         deletePrompt: "Please select the goods you want to delete",
         cancelBtn: 'Cancel the management',
         noHistory: 'No footprints',
         goodsError: {
            '1': 'Insufficient inventory',
            '2': 'Off',
            '3': 'Failure'
         },
         nextPageError: 'No path obtained'
      },
      historyRow: {
         title: "My collection",
         goodsName: "Name of goods",
         price: "The price",
         operation: "Operate",
         cancelCollect: "Cancel the collection",
         noGoods: "No goods",
      },

   },
   invoice_pc: {
      myInvoice: {
         myInvo: {
            title: "My invoice",
            tabTitle: ["Invoice information", "Invoice", "VAT information"],
            thead: [
               "The time of make out an invoice",
               "Invoice type",
               "The type of make out an invoice",
               "Name of make out an invoice",
               "Taxpayer identification number",
               "Invoice amount",
               "State",
            ],
            userinvType: [
               "Electronic plain invoice",
               "VAT paper ordinary invoice",
               "Special VAT invoice",
            ],
            userinvSort: ["Personal", "Company"],
            prompt: [
               "Already submitted",
               "Already make out an invoice",
               "Failure of make out an invoice",
            ],
            edit: "Edit",
            explain:
               "We will finish the audit within one working day. Please note that only one valid VAT invoice is qualified",
            companyName: "Company name",
            companyNamePlace: "Please enter the company name",
            userinvNo: "Taxpayer identification number",
            userinvNoPlace: "Please enter taxpayer identification number",
            userinvAdd: "Invoice address",
            userinvAddPlace: "Please enter invoice address",
            userinvPhone: "The phone of make out an invoice",
            userinvPhonePlace: "Please enter invoice number",
            userinvBankname: "Opening bank",
            userinvBanknamePlace: "Please enter opening bank",
            userinvBankno: "Account",
            userinvBanknoPlace: "Please enter account number",
            addImg: "Add images",
            qualification: "Taxpayer qualification certificate",
            userinvUname: "Name of receiver",
            userinvUnamePlace: "Please enter the name of the receiver",
            userinvUphone: "Mobile phone number of receiver",
            userinvUphonePlace: "Please enter the receiver's cell phone",
            uAddress: "Receiver's address",
            userinvUadd: "Address details",
            placeholder: "Please enter the detailed address",
            confirm: "Confirm",
            cancel: "Cancel",
            qaPrompt: [
               "The qualification review of additional ticket submitted by you failed",
               "The qualification of additional ticket submitted by you is waiting for approval. Please wait patiently",
            ],
            applyBtn: "Reapply",
            tabs: [
               "All",
               "Already submitted",
               "Already make out an invoice",
               "Failure of make out an invoice",
            ],
            errorPrompt: [
               "Incorrect input of billing phone",
               "The recipient's mobile number is not entered correctly",
               "Please enter the correct taxpayer identification number",
               "Account number is pure number",
               "Please fill in the required items",
               "The image format is JPG, PNG and GIF",
               "The uploaded image size cannot exceed 5MB",
            ],
         },
      },
      invoiceInfo: {
         Info: {
            title: "New invoice",
            addTitle: "New invoice information",
            thead: [
               "Name",
               "Operate",
               "Company name",
               "Taxpayer identification number",
            ],
            edit: "Edit",
            delete: "Delete",
            diaTitle: "Invoice title type",
            editInfo: "Edit invoice information",
            tabType: ["Personal", "Company"],
            invoiceName: "The name of the invoice",
            companyName: "Company name",
            infoError: ["Please fill in the complete information"],
            userinvMemberPlace: "Please enter invoice name",
            userinvNo: "Taxpayer identification number",
            userinvNoPlace: "Please enter taxpayer identification number",
            userinvNoError: [
               "Please fill in the taxpayer identification number",
               "Please fill in the taxpayer identification number",
            ],
            userinvUname: "Name of receiver",
            userinvUnamePlace: "Please enter the name of the receiver",
            userinvUphone: "Mobile phone number of receiver",
            userinvUphonePlace: "Please enter the receiver's cell phone",
            userinvUphoneError: "Please enter the correct mobile phone number",
            userinvUeamil: "Receiver's mailbox",
            userinvUeamilPlace: "Please enter the receiver's email address",
            userinvUeamilError: "Please enter the correct email number",
            address: "Receiver's address",
            placeholder: "Please select a",
            addressDetails: "Address details",
            userinvUadd: "Please enter the detailed address",
            confirm: "Confirm",
            cancel: "Cancel",
            diaDeletePrompt: "Confirm deleting this invoice",
            success: "Operation successful",
            error: "Operation failed",
         },
      },
      issueInvoice: {
         issue: {
            title: "Invoice",
            orderNo: "Order number",
            payName: "Real payment",
            wInvoice: "Invoice",
            diaTitle: "Invoice title type",
            invoicePrompt:
               "Electronic ordinary invoice and ordinary invoice have the same legal effect, can support reimbursement into the account",
            invoiceNotice: "Instructions for invoices",
            invoiceTypeTitle: "Invoice type",
            invoiceType: [
               "Electronic plain invoice",
               "VAT paper ordinary invoice",
               "Special VAT invoice",
            ],
            invoiceTitle: "Invoice title type",
            tabType: ["Personal", "Company"],
            invoiceName: "The name of the invoice",
            invoiceNamePlace: "Please enter invoice name",
            placeholderSelect: "Please select a",
            selectHave: "Select existing",
            userinvUname: "Name of receiver",
            userinvUnamePlace: "Please enter the name of the receiver",
            userinvUphone: "Mobile phone number of receiver",
            userinvUphonePlace: "Please enter the receiver's cell phone",
            userinvMember: "Company name",
            userinvMemberPlace: "Please enter the company name",
            address: "Receiver's address",
            userinvUadd: "Please enter the detailed address",
            userinvAdd: "Invoice address",
            userinvAddPlace: "Please enter invoice address",
            userinvNo: "Taxpayer identification number",
            userinvNoPlace: "Please enter taxpayer identification number",
            userinvPhone: "The phone of make out an invoice",
            userinvPhonePlace: "Please enter invoice number",
            userinvBankname: "Opening bank",
            userinvBanknamePlace: "Please enter opening bank",
            userinvBankno: "Account",
            userinvBanknoPlace: "Please enter account number",
            addImg: "Add images",
            userinvUEamilRules: "Please enter the correct email address",
            userinvUTrueEamilRules: "Please enter the correct email format",
            userinvUnameRules: "Please enter the name of the receiver",
            userinvUphoneRules: "Please enter the correct mobile phone number",
            userinvUaddRules: "Please enter the receiver's address",
            userinvNoRules: "Please enter taxpayer identification number",
            confirm: "Confirm",
            cancel: "Cancel",
            errorPrompt: [
               "Please fill in the required fields",
               "Please enter the correct taxpayer identification number",
               "Please fill in the correct mobile phone number",
               "Please fill in the detailed address",
               "请填写正确的开票电话",
            ],
            imgError: [
               "Upload one picture at most",
               "Image format for the common JPG, PNG, GIF format",
               "The size of uploaded pictures cannot exceed 5MB!",
            ],
         },
      },
   },
   pc: {
      evaluate: {
         myComment: {
            title: 'My evaluation',
            headerTab: ['Goods', 'Evaluation'],
            score: 'Grade'
         },
         Comment: {
            title: 'Product review',
            subbutton: 'Submit',
            failed: 'Failed',
            message: 'success',
            evPrompt: 'Store star rating should be complete',
            imglimitSize: 'The uploaded image size cannot exceed 5MB!',
            imgFormat: 'The image format is JPG, PNG and GIF',
         },
         myConsult: {
            customerService: 'Customer service',
            noReply: 'No reply'
         }
      },
      order: {
         index: {
            orderNoPlace: 'Order No.',
            search: 'Query',
            confirm: "Confirm",
            cancel: 'Cancel',
            userName: 'Name',
            userNamePlace: 'Please enter name',
            userCard: 'Id number',
            userCardPlace: 'Please enter your id number',
            address: 'Your area:',
            placeholderSelect: 'Please select a',
            addressInfo: 'Detailed address：',
            addressInfoPlace: 'detailed address, street, house number, etc',
            email: 'Contact email',
            emailPlace: "Please enter email",
            fax: 'Fax',
            faxPlace: 'Please enter fax',
            tips: 'Note: the name cannot be changed after filling in the confirmation, please check carefully',
            phoneError: ['Please enter the correct mobile phone number', 'Please enter the mobile phone number'],
            cardError: ['Please enter the correct id number', 'Please enter your id number'],
            nav: 'My order',
            rules: ['Please select province', 'Please select city', 'Please select a region', 'Please fill in the detailed address'],
            certificationTitle: 'Information complete',
            tabTitle: ['All', 'Non-payment', 'Being processed', 'Dispatched', 'Done', 'Cancel', 'Post-Sale'],
            refundState: {
               "0": "Submit the application successfully and wait for the seller to review",
               "1": "Pass the audit",
               "2": "Buyer's delivery...",
               "3": "Seller receives goods",
               "4": "Refund",
               "5": "Seller rejects application",
               "6": "Seller rejects goods",
               "7": "Refund to complete",
               "-1": "Revocation of refund application",
               "8": "Refund to complete"
            },
            msgPrompt: ['Confirm receipt', 'Confirm the cancellation of this order', 'No express delivery information', 'Contract signing']

         },
         order_ui: {
            orderItem: {
               order: "Order",
               name: "Consignee",
               orderState: "State",
               realPay: "Amount",
               operation: "Operate",
               orderDetails: "Order details",
               refund: "The refund amount",
               serviceType: "Service type",
               processState: "Processing state",
               orderNum: "Order No.：",
               yetOrder: "Already under order",
               atOncePay: "Pay",
               cancelOrder: 'Cancel',
               signedContract: "Contract signed",
               viewContract: "View Contract",
               viewProgress: "View Progress",
               viewOrder: "query",
               confirmGoods: "Confirm",
               viewLogistics: "Logistics",
               evaluation: ["Have evaluation", "Evaluation"],
               refundNum: "Refund number：",
               orderTime: "Time: ",
               returnRefund: "refunds",
               onlyRefund: "Only a refund",
               viewDetails: "View details",
               noOrder: "No relevant orders at present",
               orderType: {
                  "00": "Physical order",
                  "04": "Project order",
                  "05": "Gift package purchase",
                  "06": "Integral order",
                  "07": "Virtual order",
                  "08": "Gift package exchange order",
                  "09": "Rights and interests order",
                  "10": "Prize exchange order",
                  "11": "Service order",
                  "12": "Card order"
               },
               dataState: ["Cancel", "Initialize", "Non-payment", "Being processed", "Dispatched", "Success"],

            }
         },
         orderInfor: {
            orderNo: "Order no. ：",
            cancelOrder: "Cancel order",
            atOncePay: "pay",
            viewLogistics: "View logistics",
            confirmGoods: "Confirm goods",
            payDown: ['Offline payment', 'Online payment'],
            toEvaluate: "To evaluate",
            yetCancel: "Has been cancelled",
            buyerOrder: "Buyer has placed order",
            waitSeller: "Waiting for the seller to deliver",
            sellerWait: "The seller has delivered the goods, waiting to confirm the receipt",
            complete: "Complete transaction",
            waitEvaluate: "Deal closed, to be evaluated",
            payFailer: "Pay for failure",
            paySuccess: "Paid successfully",
            address: "Delivery address",
            orderTime: "Order time",
            payWay: "Method of payment",
            onlinePay: "Online payment",
            logisticsMsg: "Logistics information",
            No: "Temporarily no",
            getGoodsMsg: "Receiving information",
            payTime: "Payment time",
            goodsMsg: "Goods information",
            goodsName: "Goods name",
            price: "Price",
            quantity: "Quantity",
            discountAmount: "Discount amount",
            totle: "Subtotal",
            operation: "Operate",
            refund: "Refund",
            afterSales: "After sale",
            goodsTotalAmount: "Total amount of goods:",
            freight: "Freight",
            memberEquity: "Rights and interests of members",
            Payable: "Payable (including freight)",
            realPay: "Real payment (including freight)",
            noNeedPay: "No payment required",
            confirm: 'Confirm',
            cancel: 'Cancel',
            confirmGetGoods: "Confirm receipt",
            confirmCancelOrder: "Confirm the cancellation?",
            noExpressMsg: "There is no express information"
         }
      },
      coupon: {
         couponList: {
            couponTab: ['Available coupon', 'Has been used', 'Expired'],
            couponUi: {
               unit: ['Yuan', 'Reduce', 'Discount'],
               dateTitle: 'The period of validity：',
               couponNone: 'No coupon'
            },

         }
      }
   },
   order: {
      storderList: {
         plat: "platform",
         platPlaceholder: "Please enter the platform order number",
         platOrder: "Platform order number"
      },
      storeOrderList: {
         plat: "platform",
         erpName: "Enter",
         platPlaceholder: "Please enter the platform order number",
         platSltPlaceholder: "Please select platform",
         platOrder: "Platform order number",
         erpCode: "Enter Order",
         erpDate: "EnterTime",
         erpAmt: "Enter Amount",
      },
      apply: {
         num: 'Number',
         refundMoney: 'Refund amount',
         refundEx: "Fill in the reason for return",
         leixing: "Type",
         refundMon: "Refund only",
         refundMonAndthing: "refunds",
         exchangeEx: "Return reason",
         placeholder: "Please select a",
         memo: "Please describe the problem encountered and the reasons for return",
         uploadPic: "Upload image",
         detailMeo: "Detailed description",
         submit: "Submit an application",
         cancel: "Cancel",

      }
   },
   integralPc: {
      index: {
         title: "Available integral",
         thead: ['Source/Purpose', 'Integral change', 'Date'],
         nonePrompt: "No integral!"
      }
   },
   message: {
      list: {
         title: 'System Info',
         dataNone: 'No message',
         viewDetails: "View details"
      }
   },
   userPc: {
      certification: {
         title: "Authentication information",
         selected: 'Please select a',
         state: 'State:',
         type: "Type:",
         realName: "Real name:",
         inArea: ["Your area", "Your area："],
         detailedAddress: ['Address：', 'detailed address, street, house number, etc'],
         legalName: " A legal person's name:",
         IdNumber: "Id No:",
         IdPicture: "Id card:",
         positive: 'Face of id card',
         reverse: "Reverse side of id card",
         businessLicense: 'The business license:',
         ensure: "Confirm",
         cancel: "Cancel",
         validateIdPictureNone: "Id card front and back not uploaded",
         validatePositiveError: "Id card front not uploaded",
         validateReverseError: "The reverse side of the id card was not uploaded",
         validateAddressNone: "Please fill in the detailed address",
         validateNameNone: "Please fill in the consignee",
         dataState: ["Certification by", "Registration to be examined", "unauthorized", "Certification audit", "Authentication failed"],
         userinfoType: {
            "1": "Personal",
            "2": "Company"
         },
         companyAddress: "The address cannot be empty",
         userinfoCorp: "A legal person's name",
         userinfoCoid: ["Id number cannot be empty", "The form of id number is not correct"],
         userinfoCertUrl: "Need to upload business license",
         areaCode: 'Select address',
         picError: "Image format for the common JPG, PNG, GIF format!",
         picError2: "The size of uploaded images cannot exceed 5MB!",
         submitSuccess: 'Information submitted successfully',
      },
      certificationDt: {
         title: "Authentication information",
         selected: 'Please select a',
         placeholder: "Please enter your country",
         placeholderT: "Please enter your state",
         state: 'State:',
         type: "ID Type:",
         realName: "Real name:",
         inArea: ["Your area", "Your area："],
         detailedAddress: ['Address：', 'detailed address, street, house number, etc'],
         legalName: " A legal person's name:",
         IdNumber: "ID No:",
         IdPicture: "ID detail:",
         positive: 'Face of id card',
         reverse: "Reverse side of id card",
         businessLicense: 'The business license:',
         ensure: "Confirm",
         cancel: "Cancel",
         validateIdPictureNone: "Id card front and back not uploaded",
         validatePositiveError: "Id card front not uploaded",
         validateReverseError: "The reverse side of the id card was not uploaded",
         validateAddressNone: "Please fill in the detailed address",
         validateNameNone: "Please fill in the consignee",
         dataState: ["Certification by", "Registration to be examined", "unauthorized", "Certification audit", "Authentication failed"],
         userinfoType: {
            "1": "Personal",
            "2": "Company"
         },
         companyAddress: "The address cannot be empty",
         userinfoCorp: "A legal person's name",
         userinfoCoid: ["Id number cannot be empty", "The form of id number is not correct"],
         userinfoCertUrl: "Need to upload business license",
         areaCode: 'Select address',
         picError: "Image format for the common JPG, PNG, GIF format!",
         picError2: "The size of uploaded images cannot exceed 5MB!",
         submitSuccess: 'Information submitted successfully',
      },
      modules: {
         auctionAng: {
            title: "My bid",
            seeBid: "View all bids",
            bidNotice: "Name of bid announcement",
            bidTime: "Bidding time",
            margin: "Margin money:",
            bidState: "Bidding state:",
            operation: "Operate",
            session: "Auction Code：",
            winBid: "The winning",
            loseBid: "Lose a bid",
            note: ["Remark：", "Remark：After re-upload, the platform shall be approved before participating in bidding"],
            toPay: "To pay",
            toOffer: "To offer",
            viewDetails: "View details",
            resubmit: "To resubmit",
            resubmitAptitude: "Resubmit qualifications",
            uploadAptitude: "Qualifications required to upload：",
            aptitudePlace: "Please upload qualification",
            confirm: "Confirm",
            cancel: "Cancel",
            state: ['Margin to be paid', 'Waiting for audit', 'Pass the audit', 'In the bidding', 'Has ended'],
            uploadPicError: ["Image format for the common JPG, PNG, GIF format！", "The size of uploaded images cannot exceed 2MB!"],
         },
         collection: {
            title: "My collection",
            all: "All >",
            message: ["No path obtained", "That's the first one!", "No more!"],
         },
         collectionAng: {
            title: "My collection",
            allCollect: "All the collection >",
            bidCollect: "Auction collection",
            storeCollect: "Store collection",
            goodsCollect: "Goods collection",
            dataState: ["ongoing", "Waiting to start", "Has ended"],
            margin: "Margin:",
            noCollect: "No collection",
            message: "No path obtained",
         },
         header: {
            member: "Regular members",
            changeProfile: "Modification of personal particulars",
            lists: ["Waiting for the payment", "Wait for the delivery", "Has been completed", "Has been cancelled", "Return the goods"],
         },
         headerAng: {
            upSeller: "Upgrade to seller",
            changePwd: "Change the password",
            userIntegral: "User integral：",
            myAccount: "My account",
            myMargin: "My available margin：",
            viewDetails: "Query",
            allMargin: "All margin：",
            freezeMargin: "Frozen margin：",
            topUp: "Top-up",
            withdrawal: "Withdrawal",
            myMessage: "My news",
            systemMsg: "System message",
            consulting: "My advice",
            toSee: "Query>",
            member: ["Card member", "Card member-LV"],

         },
         headerUcc: {
            member: "Card member",
            changeProfile: "revise",
            lists: ["Non-payment", "Being processed", "Dispatched", "Evaluation"],
         },
         order: {
            title: "My collection",
            all: "All",
            orderCode: "Order No.",
            name: 'The consignee',
            orderState: "state",
            money: "Amount",
            operation: "Operate",
            integral: "Integral",
            dataState: ["Audit", "Non-payment", "Being processed", "Dispatched", "Done", "Success", "Cancelled"],
            viewDetails: "Query",
            atOncePay: "Pay",
            cancelOrder: 'Cancel',
            yetCancel: "Cancelled",
            confirmGoods: "confirm",
            viewLogistics: "Logistics",
            evaluation: ["Evaluated", "Evaluation"],
            noOrder: "No orders",
            confirm: "Confirm",
            cancel: "Cancel",
            msgConfirmGoods: "confirm",
            msgCancelOrder: "Cancel the order",
            msgError: "No express delivery information",
         },
         orderUcc: {
            title: "My collection",
            all: "All",
            orderCode: "Order No.：",
            name: 'The consignee',
            orderState: "State",
            money: "Amount",
            operation: "Operate",
            integral: "Integral",
            dataState: ["Audit", "Non-payment", "Being processed", "Dispatched", "Done", "Success", "cancelled"],
            viewDetails: "Query",
            atOncePay: "pay",
            cancelOrder: 'Cancel',
            yetCancel: "cancelled",
            confirmGoods: "confirm",
            viewLogistics: "Logistics",
            evaluation: ["Evaluated", "Evaluation"],
            noOrder: "No orders",
            confirm: "Confirm",
            cancel: "Cancel",
            msgConfirmGoods: "Goods received",
            msgCancelOrder: "Cancel the order",
            msgError: "No express delivery information",
         }
      }
   },
}