// import Cookies from 'js-cookie'
import picListApi from '@/api/cms/picListApi.js'
const picStore = {
    state: {

    },
    mutations: {

    },
    actions: {
        //获取列表数据
        getAdvertisePage: ({ commit, state }, obj) => {
            return picListApi.queryAdvertisePage(obj).then(res => {
                return res;
            })
        },
        //获取栏目列表
        queryPicMenuPage: ({ commit, state }, obj) => {
            return picListApi.getTginfoMenuPage(obj).then(res => {
                return res;
            })
        },
    }
}

export default picStore