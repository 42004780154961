export default {
   loginPc: {
      angang: {
         angangLogin: {
            vLogin: {
               title: '会员登录',
               tabTitle: ['账号密码登录', '手机验证码登录'],
               nameLabel: '账户',
               namePlace: '请输入账号',
               pwdLabel: '密码',
               pwdPlace: "请输入密码",
               codeLabel: "验证码",
               codePlace: "请输入验证码",
               remPwd: "记住密码",
               phoneLabel: "+86（中国大陆）",
               phonePlace: "请输入手机号",
               msgPlace: "请输入短信验证码",
               loginAuto: "7天内自动登录",
               loginBtn: "登录",
               forgetLabel: "忘记密码?",
               noAccount: "没有账号?",
               gotoSign: "去注册",
               getMsgCode: "获取验证码",
               surplus: "剩余",
               rules: {
                  phoneError: "请输入正确的手机号",
                  codeError: "验证码输入错误"
               }
            }
         }
      },
      regPc: {
         regBus: {
            vLogin: {
               sign: '注册',
               enterprise: ['企业', '个人'],
               selected: '请选择',
               readAgree: '我已阅读并同意',
               reset: '重置',
               title: '会员登录',
               tabTitle: ['账号密码登录', '手机验证码登录'],
               nameLabel: '账户',
               namePlace: '请输入账号',
               companyNamePlace: '请输入公司名称',
               youNamePlace: '请输入姓名',
               pwdLabel: '密码',
               pwdPlace: "请输入密码",
               pwdsPlace: '请输入6-20位由数字或字母组成的密码',
               codeLabel: "验证码",
               codePlace: "请输入验证码",
               numCodePlace: "请输入6位数字验证码",
               remPwd: "记住密码",
               phoneLabel: "+86（中国大陆）",
               phonePlace: "请输入手机号",
               msgPlace: "请输入短信验证码",
               loginAuto: "7天内自动登录",
               loginBtn: "登录",
               forgetLabel: "忘记密码?",
               noAccount: "没有账号?",
               gotoSign: "去注册",
               atOnceSign: "立即注册",
               vipSign: '《会员注册协议》',
               existing: '已有账号？直接登录',
               getMsgCode: "获取验证码",
               surplus: "剩余",
               rules: {
                  phoneError: "请输入正确的手机号",
                  codeError: "验证码输入错误"
               },
               check: "请您先阅读会员协议",
            }
         }
      },
      loginUi: {
         forgetPw: {
            title: "找回密码",
            enterPhone: "输入手机号",
            codeLabel: "输入验证码",
            pwdPlace: "输入新密码",
            confirm: '确定',
            toLogin: "去登录",
            pwdError: "请输入新密码",
            pwdsPlace: '请输入6-12位由数字或字母组成的新密码',
            phonePlace: "请输入手机号",
            phoneError: "请输入正确的手机号",
            phoneNoSign: "手机号未注册",
            codeError: "验证码输入错误",
            codePlace: "请输入验证码",
            numCodePlace: "请输入6位数字验证码",
            codes: "获取验证码",
            remain: "剩余",
            s: "秒",
         },
         window_login: {
            title: "登录",
            accountPlace: "输入账号",
            pwdPlace: "输入密码",
            phoneCodeLogin: "手机验证码登录",
            login: "登录",
            forgetPwd: "忘记密码？",
            toSign: "去注册",
            pwdError: "请输入密码",
            pwdErrorPlace: "请输入6-20位由数字或字母组成的密码",
            phoneRules: "输入账号",
         },
         window_loginCode: {
            title: "手机登录",
            enterPhone: "输入手机号",
            codeLabel: "输入验证码",
            codes: "获取验证码",
            login: "登录",
            toSign: "去注册",
            accountPwd: "账号密码登录",
            phonePlace: "请输入手机号",
            phoneError: "请输入正确的手机号",
            codePlace: "请输入验证码",
            numCodePlace: "请输入6位数字验证码",
            remain: "剩余",
            s: "秒",
         }
      }
   },
   disChannel: {
      order: {
         storeAbnormalOrder: {
            plat: "平台",
            platPlaceholder: "请输入平台订单号",
            platOrder: "平台订单号",
            promptTitle: '确定是否重新推送平台数据？'
         },

      }
   },
   pc: {
      evaluate: {
         myComment: {
            title: '我的评价',
            headerTab: ['商品', '评价'],
            score: '评分'
         },
         Comment: {
            title: '评论商品',
            subbutton: '提交',
            failed: '评论失败',
            message: '评论成功',
            evPrompt: '商铺星星评分要完整',
            imglimitSize: '上传的图片大小不能超过 5MB!',
            imgFormat: '图片格式为常见的jpg、png、gif格式'
         },
         myConsult: {
            customerService: '客服',
            noReply: '暂无回复'
         }
      },
      coupon: {
         couponList: {
             couponTab: ['可用优惠券', '已使用', '已过期'],
             couponUi: {
                 unit: ['元', '减', '折'],
                 dateTitle: '有效期：',
                 couponNone: '暂无优惠券'
             },

         }
     }
   },
   order: {
      storderList: {
          plat: "平台",
          platPlaceholder: "请输入平台订单号",
          platOrder: "平台订单号",
          promptTitle:"确定是否重新推送平台数据？",
      },
      storeOrderList: {
          plat: "平台",
          erpName: "入机",
          platPlaceholder: "请输入平台订单号",
          platSltPlaceholder: "请选择平台",
          platOrder: "平台订单号",
          erpCode: "入机单号",
          erpDate: "入机时间",
          erpAmt: "入机金额",
      }
  },
}