import request from '#/utils/request'

let docListApi = {
    queryDoclist : (data) => { 
        return request({
            url: '/web/cms/doclist/queryDoclistPage.json',
            method: 'get',
            params: data
        })
    },
    queryTginfoMenuPage: (data) => {
        return request({ //栏目列表
            url: '/web/cms/tginfoMenu/queryTginfoMenuPage.json',
            method: 'get',
            params: data
        })
    },
    getTginfoMenuPage: (data) => {
      return request({ //栏目列表
         url: '/web/cms/tginfoMenu/queryTginfoMenuPage.json',
         method: 'get',
         params: data
      })
   },
   queryTginfoPage: (data) => { //获取站点下拉
      return request({
          url: '/web/cms/tginfo/queryTginfoPage.json',
          method: 'get',
          params: data
      })
  },
   
}

export default docListApi;