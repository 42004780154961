export default {
    /**
     * 营销代办
     */

    // 页面查询列表
    findMarketingPendingList: {
        method: 'post',
        url: 'web/mkp/findMarketingPendingList.json'
    },
    // 审核详情
    mkpQueryMarketingDetail: {
        method: 'post',
        url: 'web/mkp/queryMarketingDetail.json'
    },
      // 查看活动商品详情
      mkpQueryMarketingGoodsInfo: {
        method: 'post',
        url: 'web/mkp/queryMarketingGoodsInfo.json'
    },
    // 营销审核
    auditMarketingAudit: {
        method: 'post',
        url: 'web/mkp/auditMarketingAudit.json'
    },
    // 页面查询列表
    mkpFindMarketingAuditList: {
        method: 'post',
        url: 'web/mkp/findMarketingAuditList.json'
    },// 查看详情
    mkpFindMarketingDetail: {
        method: 'post',
        url: 'web/mkp/findMarketingAuditDetail.json'
    },
    mkpDownStream: {
        method: 'post',
        url: 'web/mkp/downStream.json',
        responseType: 'blob',
    },
    // 成本分摊配置 查询接口
    mkpQueryInvestor: {
        method: 'post',
        url: 'web/mkp/queryInvestor.json'
    },
    // 成本分摊配置 查询出资方式、账户、机构
    mkpQueryInvestInfo: {
        method: 'post',
        url: 'web/mkp/queryInvestInfo.json'
    },
     // 预付出资金额
     mkpQueryInvestDueRate: {
        method: 'post',
        url: 'web/mkp/queryInvestDueRate.json'
    }, 
    /**
     * 审核详情商品列表
     */
    mkpFindMarketingAuditGoodsList: {
        method: 'post',
        url: 'web/mkp/findMarketingAuditGoodsList.json',
        config: {
            btnLoading: true,
        }
    },
}