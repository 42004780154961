export default {
    // 商品组查询
    cmsBusSeatqueryList: {
        method: "post",
        url: "/web/seat/cmsBusSeatCon/queryList.json",
        // type: 'x-www-form',
    },
    //商品组页面
    cmsBusGoodsqueryList: {
        method: "post",
        url: "/web/group/cmsBusGoodsGroupCon/queryList.json",
        // type: 'x-www-form',
    },
    cmsBusGoodssaveGoodsGroup: {//新增商品组
        method: "post",
        url: "/web/group/cmsBusGoodsGroupCon/saveGoodsGroup.json",
        // type: 'x-www-form',
    },
    cmsBusGoodsqueryGoods: {//查询商品
        method: "post",
        url: "/web/goods/cmsBusGoodsCon/queryGoods.json",
        // type: 'x-www-form',
    },
    cmsBusSeatConsaveSeat: {//创建位置
        method: "post",
        url: "/web/seat/cmsBusSeatCon/saveSeat.json",
        // type: 'x-www-form',
    },
    cmsBusSeatmodifySeatName: {//修改位置名称
        method: "post",
        url: "/web/seat/cmsBusSeatCon/modifySeatName.json",
        // type: 'x-www-form',
    },
    removeGoodsGroup: {//删除商品组
        method: "post",
        url: "/web/group/cmsBusGoodsGroupCon/removeGoodsGroup.json",
        // type: 'x-www-form',
    },
    modifyGoodsGroup: {//管理商品组
        method: "post",
        url: "/web/group/cmsBusGoodsGroupCon/modifyGoodsGroup.json",
        // type: 'x-www-form',
    },
    queryGoodsGroup: {//管理商品组回显
        method: "post",
        url: "/web/group/cmsBusGoodsGroupCon/queryGoodsGroup.json",
        // type: 'x-www-form',
    },
    downloadGoodsExcel: {//模板下载
        method: "post",
        url: "/web/goods/cmsBusGoodsCon/downloadGoodsExcel.json",
        // type: 'x-www-form',
        white: true,
        responseType: 'blob'
    },
    uploadBatchAddGoodsExcel: {//模板导入
        method: "post",
        url: "/web/goods/cmsBusGoodsCon/uploadBatchAddGoodsExcel.json",
        // type: 'x-www-form',
    },
    queryGoodsBindGoodsGroup: {//查询已导入商品
        method: "post",
        url: "/web/goods/cmsBusGoodsCon/queryGoodsBindGoodsGroup.json",
        // type: 'x-www-form',
    },
}