export default {
    /**
     * 优惠券列表
     */

    // 文件上传
    reductionUploadSignedFile: {
        method: 'post',
        url: 'web/reduction/uploadSignedFile.json'
    },
    // 预付出资金额
    reductionQueryInvestDueRate: {
        method: 'post',
        url: 'web/reduction/queryInvestDueRate.json'
    }, 
    // 页面查询列表
    reductionQueryMarketingList: {
        method: 'post',
        url: 'web/reduction/queryMarketingList.json'
    }, 
    // 添加活动
    reductionAddMarketing: {
        method: 'post',
        url: 'web/reduction/addMarketing.json',
        config: {
            btnLoading: true,
        }
    },
    // 查看活动商品详情
    reductionQueryMarketingGoodsInfo: {
        method: 'post',
        url: 'web/reduction/queryMarketingGoodsInfo.json'
    },
    // 查看活动信息
    reductionQueryMarketingInfo: {
        method: 'post',
        url: 'web/reduction/queryMarketingInfo.json'
    },
    // 更新活动
    reductionUpdateMarketing: {
        method: 'post',
        url: 'web/reduction/updateMarketing.json',
        config: {
            btnLoading: true,
        }
    },
    // 活动详情
    reductionQueryMarketingDetail: {
        method: 'post',
        url: 'web/reduction/queryMarketingDetail.json'
    },
    
    // 预付出资金额比列
    reductionQueryInvestDueRate: {
        method: 'post',
        url: 'web/reduction/queryInvestDueRate.json'
    },
    // 终止活动
    reductionTerminateMarketing: {
        method: 'post',
        url: 'web/reduction/terminateMarketing.json',
        config: {
            btnLoading: true,
        }
    }, 
    // 重新认款
    reductionMarketingRecognize: {
        method: 'post',
        url: 'web/reduction/marketingRecognize.json',
        config: {
            btnLoading: true,
        }
    },
     // 追加认款
     reductionMarketingRecognizeAppend: {
        method: 'post',
        url: 'web/reduction/marketingRecognizeAppend.json',
        config: {
            btnLoading: true,
        }
    },
    // 认款列表
    reductionQueryMarketingRecognizeList: {
        method: 'post',
        url: 'web/reduction/queryMarketingRecognizeList.json'
    },
    // 审核状态列表
    reductionFindMarketingAuditList: {
        method: 'post',
        url: 'web/reduction/findMarketingAuditList.json'
    },
    // 下载链接
    reductionDownStream: {
        method: 'post',
        url: 'web/reduction/downStream.json',
        responseType: 'blob',
    },
     // 取消修改
    reductionCancelMarketing: {
        method: 'post',
        url: 'web/reduction/cancelMarketing.json',
    },
     // 成本分摊配置 查询接口
    reductionQueryInvestor: {
        method: 'post',
        url: 'web/reduction/queryInvestor.json'
    },
    // 成本分摊配置 查询出资方式、账户、机构
    reductionQueryInvestInfo: {
        method: 'post',
        url: 'web/reduction/queryInvestInfo.json'
    },
      /**
     * 审核详情商品列表
     */
    reductionFindMarketingAuditGoodsList: {
        method: 'post',
        url: 'web/reduction/findMarketingAuditGoodsList.json',
        config: {
            btnLoading: true,
        }
    },
    // 查看详情
    reductionFindMarketingDetail: {
        method: 'post',
        url: 'web/reduction/findMarketingAuditDetail.json'
    },
    // 查询所有商品信息
    reductionPageSkuInfo: {
        method: 'post',
        url: '/web/reduction/pageSkuInfo.json'
    },
    
}