export default {
    /**
     * 秒杀活动
     */

    // 文件上传
    seckillUploadSignedFile: {
        method: 'post',
        url: 'web/seckill/uploadSignedFile.json'
    },
    // 预付出资金额
    seckillQueryInvestDueRate: {
        method: 'post',
        url: 'web/seckill/queryInvestDueRate.json'
    }, 
    // 页面查询列表
    seckillQueryMarketingList: {
        method: 'post',
        url: 'web/seckill/queryMarketingList.json'
    }, 
    // 终止活动
    seckillTerminateMarketing: {
        method: 'post',
        url: 'web/seckill/terminateMarketing.json',
    }, 
    // 添加活动
    seckillAddMarketing: {
        method: 'post',
        url: 'web/seckill/addMarketing.json',
        config: {
            btnLoading: true,
          }
    },
    // 查看活动商品详情
    seckillQueryMarketingGoodsInfo: {
        method: 'post',
        url: 'web/seckill/queryMarketingGoodsInfo.json'
    },
    // 查看活动信息
    seckillQueryMarketingInfo: {
        method: 'post',
        url: 'web/seckill/queryMarketingInfo.json'
    },
    // 活动详情
    seckillQueryMarketingDetail: {
        method: 'post',
        url: 'web/seckill/queryMarketingDetail.json'
    },
    // 更新活动
    seckillUpdateMarketing: {
        method: 'post',
        url: 'web/seckill/updateMarketing.json',
        config: {
            btnLoading: true,
        }
    },
    
    // 预付出资金额比列
    seckillQueryInvestDueRate: {
        method: 'post',
        url: 'web/seckill/queryInvestDueRate.json'
    },
    // 终止活动
    seckillTerminateMarketing: {
        method: 'post',
        url: 'web/seckill/terminateMarketing.json'
    }, 
    // 重新认款
    seckillMarketingRecognize: {
        method: 'post',
        url: 'web/seckill/marketingRecognize.json'
    },
    // 认款状态列表
    seckillQueryMarketingRecognizeList: {
        method: 'post',
        url: 'web/seckill/queryMarketingRecognizeList.json'
    },
    // 审核状态列表
    seckillFindMarketingAuditList: {
        method: 'post',
        url: 'web/seckill/findMarketingAuditList.json'
    },
    // 
    seckillFindMarketingAuditDetail: {
        method: 'post',
        url: 'web/seckill/findMarketingAuditDetail.json'
    },
    // 追加认款
    seckillMarketingRecognizeAppend: {
        method: 'post',
        url: 'web/seckill/marketingRecognizeAppend.json'
    },
     // 取消修改
    seckillCancelMarketing: {
        method: 'post',
        url: 'web/seckill/cancelMarketing.json'
    },
    // 下载链接
    seckillDownStream: {
        method: 'post',
        url: 'web/seckill/downStream.json',
        responseType: 'blob'
    },
     // 成本分摊配置 查询接口
    seckillQueryInvestor: {
        method: 'post',
        url: 'web/seckill/queryInvestor.json'
    },
    // 成本分摊配置 查询出资方式、账户、机构
    seckillQueryInvestInfo: {
        method: 'post',
        url: 'web/seckill/queryInvestInfo.json'
    },
      /**
     * 审核详情商品列表
     */
    seckillFindMarketingAuditGoodsList: {
        method: 'post',
        url: 'web/seckill/findMarketingAuditGoodsList.json',
        config: {
            btnLoading: true,
        }
    },
    // 查看详情
    seckillFindMarketingDetail: {
        method: 'post',
        url: 'web/seckill/findMarketingAuditDetail.json'
    },
    // 查询所有商品信息
    seckillPageSkuInfo: {
        method: 'post',
        url: '/web/seckill/pageSkuInfo.json'
    },
}
