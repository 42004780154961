import request from '#/utils/request'

let merpicListApi = {
    queryAdvertisePage: (data) => {
        return request({//广告位列表查询
            url: '/web/cms/advertiseBusiness/queryAdvertisePage.json',
            method: 'get',
            params: data
        })
    },
    getTginfoMenuPage: (data) => {
        return request({ //栏目列表
            url: '/web/cms/tginfoMenuBusiness/queryTginfoMenuPage.json',
            method: 'get',
            params: data
        })
    },
}

export default merpicListApi;