// import Cookies from 'js-cookie'
import docListApi from '@/api/cms/docListApi.js'
const docStore = {
  state: {
   siteList:[]
  },
  mutations: {
   SET_siteList: (state, data) => {
       state.siteList = data
   },
},
  actions: {
    //获取列表数据
    queryDoclist: ({ commit, state }, obj) => {
      return docListApi.queryDoclist().then(res => {
          return res;
        })      
    },
    //获取栏目列表
    queryMenuPage: ({ commit, state }, obj) => {
      return docListApi.queryTginfoMenuPage().then(res => {
        return res;
      })
    },
    //获取栏目列表
    queryPicMenuPage: ({ commit, state }, obj) => {
      return docListApi.getTginfoMenuPage(obj).then(res => {
          return res;
      })
   },
   getTginfoPage: ({ commit, state }, obj) => {
      return docListApi.queryTginfoPage(obj || {}).then(res => {
          if (res) {
              commit('SET_siteList', res.list)
              return res;
          }
      })
  },
  }
}

export default docStore