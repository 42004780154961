export default {
     /**
     * 审核详情, 品牌接口
     */
    queryBrandPage: {
        method: 'post',
        url: 'web/rs/brand/queryBrandPage.json',
        apiType: 'jd'
    },
    /**
     * 审核详情, 类目接口
     */
    queryClasstreeGoodsClassForRet: {
        method: 'post',
        url: 'web/rs/classtree/queryClasstreeGoodsClassForRetList.json',
        apiType: 'jd'
    },
    /**
     * 审核详情商品列表
     */
    mkaFindMarketingAuditGoodsList: {
        method: 'post',
        url: 'web/mka/findMarketingAuditGoodsList.json',
        config: {
            btnLoading: true,
        }
    },
     // 查看活动商品详情
    mkaQueryMarketingGoodsInfo: {
        method: 'post',
        url: 'web/mka/queryMarketingGoodsInfo.json'
    },
    // 页面查询列表
    mkaFindMarketingAuditList: {
        method: 'post',
        url: 'web/mka/findMarketingAuditList.json'
    },
    // // 编辑
    // updateMarketingAudit: {
    //     method: 'post',
    //     url: 'web/mka/updateMarketingAudit.json'
    // },
     // 下载链接
    mkaDownStream: {
        method: 'post',
        url: 'web/mka/downStream.json',
        responseType: 'blob',
    },
    // 查看详情
    mkaFindMarketingDetail: {
        method: 'post',
        url: 'web/mka/findMarketingAuditDetail.json'
    },
    // 活动详情
    mkaQueryMarketingDetail: {
        method: 'post',
        url: 'web/mka/queryMarketingDetail.json'
    },
     // 认款状态列表
     mkaQueryMarketingRecognizeList: {
        method: 'post',
        url: 'web/mka/queryMarketingRecognizeList.json'
    },
    // 编辑详情
    mkaUpdateMarketing: {
        method: 'post',
        url: 'web/mka/updateMarketingAudit.json',
        config: {
            btnLoading: true,
        }
    },
    // 取消审核
    cancelMarketingAudit: {
        method: 'post',
        url: 'web/mka/cancelMarketingAudit.json',
    },  
    // 修改审核 枚举(商品修改)
    // findMarketingAuditGoodsList: {
    //     method: 'post',
    //     url: 'web/mka/findMarketingAuditGoodsList.json',
    //     config: {
    //         btnLoading: true,
    //     }
    // },

    // 成本分摊配置 查询接口
    mkaQueryInvestor: {
        method: 'post',
        url: 'web/mka/queryInvestor.json'
    },
    // 成本分摊配置 查询出资方式、账户、机构
    mkaQueryInvestInfo: {
        method: 'post',
        url: 'web/mka/queryInvestInfo.json'
    },
     // 预付出资金额
     mkaQueryInvestDueRate: {
        method: 'post',
        url: 'web/mka/queryInvestDueRate.json'
    }, 
    // 取消修改
    mkaCancelMarketing: {
        method: 'post',
        url: 'web/mka/cancelMarketing.json',
    },

    /***
     * 活动接口
    */
    // 优惠券活动-商品查询
    couponPageSkuInfo: {
        method: 'post',
        url: '/web/bus/coupon/pageSkuInfo.json',
    },
    // 折扣活动-商品查询
    discountPageSkuInfo: {
        method: 'post',
        url: '/web/bus/discount/pageSkuInfo.json',
    },
    // 满减类活动-商品查询
    reductionPageSkuInfo: {
        method: 'post',
        url: '/web/bus/reduction/pageSkuInfo.json',
    },
    // 秒杀活动-商品查询
    seckillPageSkuInfo: {
        method: 'post',
        url: '/web/bus/seckill/pageSkuInfo.json',
    },
}