// import Cookies from 'js-cookie'
import merpicListApi from '@/api/cms/merpicListApi.js'
const picStore = {
    state: {

    },
    mutations: {

    },
    actions: {
        //获取列表数据
        getAdvertisePageB: ({ commit, state }, obj) => {
            return merpicListApi.queryAdvertisePage(obj).then(res => {
                return res;
            })
        },
        //获取栏目列表
        queryPicMenuPageB: ({ commit, state }, obj) => {
            return merpicListApi.getTginfoMenuPage(obj).then(res => {
                return res;
            })
        },
    }
}

export default picStore