export default {
  // 查询活动列表
  queryActivityList: {
    method: 'post',
    url: '/web/bus/activity/queryActivityList.json',
    type: 'json'
  },
  queryActivityListForAdd: {
    method: 'post',
    url: '/web/bus/activity/queryActivityListForAdd.json',
    type: 'json'
  },
  // 查询活动详情
  queryActivityDetail: {
    method: 'post',
    url: '/web/bus/activity/queryActivityDetail.json',
    type: 'json'
  },
  queryActivityDetailForDetail: {
    method: 'post',
    url: '/web/bus/activity/queryActivityDetailForDetail.json',
    type: 'json'
  },
  queryActivityDetailForModify: {
    method: 'post',
    url: '/web/bus/activity/queryActivityDetailForModify.json',
    type: 'json'
  },
  // 查询活动中，需要选择的商品
  pageSkuInfo: {
    method: 'post',
    url: '/web/bus/activity/pageSkuInfo.json',
    type: 'json'
  },
  // 添加活动
  addActivity: {
    method: 'post',
    url: '/web/bus/activity/addActivity.json',
    type: 'json',
    config: {
      btnLoading: true
    }
  },
  // 修改活动状态
  modifyActivityStatus: {
    method: 'post',
    url: '/web/bus/activity/modifyActivityStatus.json',
    type: 'json',
  },
  // 活动批量关联商品
  batchImportGoods: {
    method: 'post',
    url: '/web/bus/activity/batchImportGoods.json',
    type: 'json',
    config: {
      btnLoading: true
    }
  },
  // 活动批量关联商品数据（选择商品）
  batchImportGoodsList: {
    method: 'post',
    url: '/web/bus/activity/batchImportGoodsList.json',
    type: 'json',
    config: {
      btnLoading: true
    }
  },

  // 活动批量关联商品数据（导入商品文件）
  batchImportGoods: {
    method: 'post',
    url: '/web/bus/activity/batchImportGoods.json',
    type: 'json'
  },
  // 活动批量关联白名单
  batchImportWhite: {
    method: 'post',
    url: '/web/bus/activity/batchImportWhite.json',
    type: 'json'
  },
  // 秒杀商品模版
  downActivityGoodsTemplateStream: {
    method: 'post',
    url: '/web/bus/activity/downActivityGoodsTemplateStream.json',
    responseType: 'blob'
  },
  // 秒杀商品模版 白名单
  downActivityWhiteTemplateStream: {
    method: 'post',
    url: '/web/bus/activity/downActivityWhiteTemplateStream.json',
    responseType: 'blob'

  }
}