import siteListApi from '@/api/cms/siteListApi.js'
const siteStore = {
    state: {
        proappData: [],
        mmodelData:[]
    },
    mutations: {
        SET_ProappData: (state, data) => {
            state.proappData = data
        },
        SET_MmodelData: (state, data) => {
            state.mmodelData = data
        }
    },
    actions: {
        queryProappPage: ({ commit, state }, obj) => { 
            return siteListApi.queryProappPage(obj = {}).then(res => { 
                if (res) {
                    // res.list.map(item => {
                    //     item.lable = item.proappName;
                    //     item.value = item.proappCode;
                    // })
                    commit('SET_ProappData', res.list);
                    return res;
                }
            })
        },
        queryPfsMmodelPage: ({ commit, state }, obj) => { 
            return siteListApi.queryPfsMmodelPage(obj = {}).then(res => {
                if (res) {
                    // res.list.map(item => {
                    //     item.lable = item.proappName;
                    //     item.value = item.proappCode;
                    // })
                    // commit('SET_MmodelData', res.list);
                    return res;
                }
            })
        }
    }
}

export default siteStore;