export default {
    /**
     * 优惠券列表
     */

    // 文件上传
    couponUploadSignedFile: {
        method: 'post',
        url: 'web/coupon/uploadSignedFile.json'
    },
    // 页面查询列表
    couponQueryMarketingList: {
        method: 'post',
        url: 'web/coupon/queryMarketingList.json'
    }, 
    // 添加活动
    couponAddMarketing: {
        method: 'post',
        url: 'web/coupon/addMarketing.json',
        config: {
            btnLoading: true,
        }
    },
    // 查看活动商品详情
    couponQueryMarketingGoodsInfo: {
        method: 'post',
        url: 'web/coupon/queryMarketingGoodsInfo.json'
    },
    // 查看活动信息
    couponQueryMarketingInfo: {
        method: 'post',
        url: 'web/coupon/queryMarketingInfo.json'
    },
    // 活动详情
    couponQueryMarketingDetail: {
        method: 'post',
        url: 'web/coupon/queryMarketingDetail.json'
    },
    // 更新活动
    couponUpdateMarketing: {
        method: 'post',
        url: 'web/coupon/updateMarketing.json',
        config: {
            btnLoading: true,
        }
    },
    
    // 预付出资金额比列
    couponQueryInvestDueRate: {
        method: 'post',
        url: 'web/coupon/queryInvestDueRate.json'
    },
    // 终止活动
    couponTerminateMarketing: {
        method: 'post',
        url: 'web/coupon/terminateMarketing.json',
        config: {
            btnLoading: true,
        }
    }, 
    // 重新认款
    couponMarketingRecognize: {
        method: 'post',
        url: 'web/coupon/marketingRecognize.json',
        config: {
            btnLoading: true,
        }
    },
    // 追加认款
    couponMarketingRecognizeAppend: {
        method: 'post',
        url: 'web/coupon/marketingRecognizeAppend.json',
        config: {
            btnLoading: true,
        }
    },
    // 认款状态列表
    couponQueryMarketingRecognizeList: {
        method: 'post',
        url: 'web/coupon/queryMarketingRecognizeList.json'
    },
    // 审核状态列表
    couponFindMarketingAuditList: {
        method: 'post',
        url: 'web/coupon/findMarketingAuditList.json'
    },
    // 取消修改
    couponCancelMarketing: {
        method: 'post',
        url: 'web/coupon/cancelMarketing.json',
    },
    // 下载链接
    couponDownStream: {
        method: 'post',
        url: 'web/coupon/downStream.json',
        responseType: 'blob',
    },
    // 成本分摊配置 查询接口
    couponQueryInvestor: {
        method: 'post',
        url: 'web/coupon/queryInvestor.json'
    },
    // 成本分摊配置 查询出资方式、账户、机构
    couponQueryInvestInfo: {
        method: 'post',
        url: 'web/coupon/queryInvestInfo.json'
    },
     /**
     * 审核详情商品列表
     */
     couponFindMarketingAuditGoodsList: {
        method: 'post',
        url: 'web/coupon/findMarketingAuditGoodsList.json',
        config: {
            btnLoading: true,
        }
    },
    // 查看详情
    couponFindMarketingDetail: {
        method: 'post',
        url: 'web/coupon/findMarketingAuditDetail.json'
    },
    // 查询所有商品信息
    couponPageSkuInfo: {
        method: 'post',
        url: '/web/coupon/pageSkuInfo.json'
    },
}