import request from "#/utils/request";
// import request from '../utils/request';
import API from "../api/res/index";
import store from "../store";
import { Message } from "element-ui";
import { getSessionStorage } from "./auth";

const errorText = "请求失败，请稍后重试";
// 获取操作人
const operator = () => {
  const userInfo = sessionStorage.getItem("personalInfo");
  return userInfo ? JSON.parse(userInfo).userName : "";
};

/**
 * @description: 设置加载的状态
 * @author: huchao
 */
const setLoadingState = () => {
  const { btnLoading, tableLoading } = store.getters; // 拿到当前btnloading的状态
  // 将按钮的loading状态还原
  setTimeout((e) => {
    btnLoading && store.dispatch("setBtnLoading", false); // 设置表单提交btn加载的loading
    tableLoading && store.dispatch("setTableLoading", false); // 设置table加载的loading
  }, 250);
};
/**
 * @description: 请求方法封装
 * @param {*} urlName 当前API中对应的名称
 * @param {*} data // 传递的参数
 * @return {*}
 * @author: huchao
 */
const http = (urlName, data = {}) => {
  // 每个接口都填加日志号，方便查询
  data["traceLogId"] = +new Date();
  // 当未传operator操作人时，使用默认的方式获取
  !data["operator"] && (data["operator"] = operator());
  !data["createdBy"] && (data["createdBy"] = operator());
  try {
    let { url } = API[urlName];
    const {
      method,
      type = "json",
      ContentType,
      white,
      config = { btnLoading: false },
      responseType = "json",
      result_type,
      apiType = "",
    } = API[urlName] || {};
    const proappEnvLayout = getSessionStorage("proappEnvLayout") || {};
    const proappCode = proappEnvLayout.proappCode;
    const queryTypeObj = {
      json: "application/json;charset=UTF-8",
      "x-www-form": "application/x-www-form-urlencoded;charset=UTF-8",
    };
    // 运营端和商家端同用代码时，做一个code判断，兼容代码
    if (proappCode === "002") {
      url = url.replace("/web/bus/so/", "/web/so/");
    }
    const reqObj = {
      url,
      method,
      whitePath: true, // 传给线上的白名单
      headers: {
        // 判断是不是json类型 设置不同的Content-Type
        "Content-Type": ContentType || queryTypeObj[type],
      },
      responseType: responseType,
    };
    /**
     * json： 格式为data
     * xxx-form-data : 格式为params
     */
    type === "json" ? (reqObj.data = data) : (reqObj.params = data);

    // 当设置了需要按钮loading时，在发起请求将btn的loading状态改为true, todo：不需要了，先关闭不用
    if (config.btnLoading) {
      store.dispatch("setBtnLoading", true);
    }
    // 规范：所有API中查 询列表，都需要以List结尾！！
    if (urlName.includes("List")) {
      store.dispatch("setTableLoading", true);
    }

    return new Promise((resolve, reject) => {
      request(reqObj)
        .then((res) => {
          // 如果接口为jd的，则直接返回数据
          if (apiType === "jd") return resolve(res);
          // 当返回的数据结果，与咱们自己后端统一结构不同时，设置放入白名单
          // 后续若有更多不同则添加兼容
          white && (res.result = res.dataObj || "");
          const { errorMessage, msg, success, result } = res;
          const errorStrAll = errorMessage || msg || errorText;
          if (result_type === "no_status") {
            resolve(res);
          }
          console.log(success, result, result_type);
          // success返回false时
          if (res instanceof Blob) {
            resolve(res);
          } else if (!success && result_type !== "no_status") {
            Message({
              message: errorStrAll,
              type: "error",
              duration: 1500,
            });
            reject(errorStrAll);
          } else {
            resolve(res);
          }
          setLoadingState();
        })
        .catch((err) => {
          reject(err);
          setLoadingState();
        });
    });
  } catch (error) {
    setLoadingState();
    console.error(`发生错误：===》`, `接口【${urlName}】不存在\n${error}`);
  }
};

// const upLoad = (urlName, param, config) => {
//   try {
//     const { url, method } = API[urlName];
//     return request({
//         url,
//         param,
//         method,
//         config
//     })
//   } catch (error) {
//       console.error('发生错误：===》', error);
//   }
// }

export default http;
