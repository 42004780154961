// /web/bus/openPlatform/querySyncRecordsByPage.json
export default {
    // 列表查询
    querySyncRecordsByPage: { 
        method: "post",
        url: "/web/openapi/querySyncRecordsByPage.json",
    },
    // 订单详情查询
    queryOrderDetailByCode: {
        method: "post",
        url: "/web/openapi/queryOrderDetailByCode.json",
    },
    // 退款详情查询
    queryRefundDetailByCode: { 
        method: "post",
        url: "/web/openapi/queryRefundDetailByCode.json",
    },
    // 失败记录补推
    retryPush: { 
        method: "post",
        url: "/web/openapi/retryPush.json",
    },
    // 导出失败文件
    export: {
        method: "post",
        url: "/web/openapi/export.json",
    }
}