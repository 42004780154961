export default {
    // 整单退款
    queryMerchantRule: { // 查询
        method: "post",
        url: "/web/rule/merchantRefundRule/queryMerchantRule.json",
        // type: 'x-www-form',
    },
    findLockProdoperationMerchantRefundRuleuctList: { // 删 改 增
        method: "post",
        url: "/web/rule/merchantRefundRule/operationMerchantRefundRule.json",
        // type: 'x-www-form',
    },
}