/***
 * 规范：所有API中查询列表，都需要以List结尾！！
 * */ 
import activity from "./activity";
import settlementCenter from "./settlementCenter";
import lockPrice from './lockPrice'
import refundRule from "./refundRule";
import show from "./show";
import content from './content';
import marketingAgency from './marketingAgency'
import auditList from "./auditList";
import pricedIscount from './pricedIscount'
import coupon from './coupon'
import seckill from './seckill'
import reduction from './reduction'
import discount from "./discount";
import manage from './manage';
import postpaymentList from './postpaymentList'
import apiError from "./apiError";
const cms = {
  cmsModuleQueryList: {
    // 查询模块管理列表
    method: "post",
    headers: {
    },
    url: "web/module/cmsBusModuleCon/queryList.json"
  },
  addCmsModuleQuery: {
    // 模块管理查询新增
    method: "post",
    url: "web/module/cmsBusModuleCon/saveModule.json",
    config: {
      btnLoading: true,
    }
  },
  cmsModuleQueryDelete: {
    // 模块管理查询删除
    method: "post",
    url: "web/module/cmsBusModuleCon/removeModule.json"
  },
  updataCmsModuleQuery: {
    // 模块管理查询修改
    method: "post",
    url: "web/module/cmsBusModuleCon/modifyModuleEffectiveStatus.json",
    config: {
      btnLoading: true,
    }
  },
  sortModule: {
    // 模块排序
    method: "post",
    url: "web/module/cmsBusModuleCon/sortModule.json"
  },
  cmsAuthorityQueryList: {
    // 预览权限查询
    method: "post",
    url: "web/mall/cmsBusAuthorityCon/queryList.json",
  },
  createAuthority: {
    // 新增预览权限
    method: "post",
    url: "web/mall/cmsBusAuthorityCon/saveAuthority.json",
    config: {
      btnLoading: true,
    },
  },
  removeAuthority: {
    // 删除预览权限
    method: "post",
    url: "web/mall/cmsBusAuthorityCon/removeAuthority.json",
  },

  cmsContentPageQuery: {
    // 内容管理分页接口
    method: "post",
    url: "web/content/cmsBusContentCon/pageQuery.json"
  },
  cmsContentcreate: {
    // 创建内容
    method: "post",
    url: "web/content/cmsBusContentCon/createContent.json",
    config: {
      btnLoading: true,
    }
  },
  cmsContentdetailQuery: {
    // 查询内容详情
    method: "post",
    url: "web/content/cmsBusContentCon/detailQuery.json"
  },
  cmsContentmodify: {
    // 修改内容
    method: "post",
    url: "web/content/cmsBusContentCon/modifyContent.json",
    config: {
      btnLoading: true,
    }
  },
  cmsContentremove: {
    // 删除内容
    method: "post",
    url: "web/content/cmsBusContentCon/removeContent.json"
  },
  cmsContentStatus: {
    // 修改内容状态
    method: "post",
    url: "web/content/cmsBusContentCon/modifyContentEffectiveStatus.json",
    config: {
      btnLoading: true,
    }
  },
  sortContent: {
    // 修改内容顺序
    method: "post",
    url: "/web/content/cmsBusContentCon/sortContent.json",
    config: {
      btnLoading: true,
    }
  },
  cmswaterFallListNew: {
    // 新瀑布流查询
    method: "post",
    url: "web/module/cmsBusModuleCon/queryWaterFallList.json",
  },
  cmswaterFallList: {
    // 老瀑布流查询
    method: "post",
    url: "web/waterfall/cmsBusWaterFallCon/queryList.json",
  },
  cmswaterFalladd: {
    // 瀑布流新增
    method: "post",
    url: "web/waterfall/cmsBusWaterFallCon/saveCategory.json",
    config: {
      btnLoading: true,
    },
  },
  cmswaterFallRemove: {
    // 瀑布流删除模块
    method: "post",
    url: "web/waterfall/cmsBusWaterFallCon/removeCategory.json",
  },
  cmswaterFallupdata: {
    // 瀑布流修改模块
    method: "post",
    url: "web/waterfall/cmsBusWaterFallCon/modifyCategory.json",
    config: {
      btnLoading: true,
    },
  },
  cmswaterFalldetail: {
    // 瀑布流详情模块
    method: "post",
    url: "web/waterfall/cmsBusWaterFallCon/queryDetail.json",
  },
  cmswaterFallModify: {
    // 瀑布流上线/下线接口
    method: "post",
    url: "web/waterfall/cmsBusWaterFallCon/modifyCategoryEffectiveStatus.json",
  },
  cmswaterFallsort: {
    // 瀑布流排序
    method: "post",
    url: "web/waterfall/cmsBusWaterFallCon/sortCategory.json",
  },
  pageQueryListNew: {
    // 页面列表查询
    method: "post",
    url: "web/page/cmsBusPageCon/queryList.json",
  },
  pageQueryList: {
    // 页面列表查询
    method: "post",
    url: "web/page/cmsBusPageCon/queryList.json",
  },
  savePageQuery: {
    // 添加页面列表
    method: "post",
    url: "web/page/cmsBusPageCon/savePage.json",
  },
  modifyPageEffectiveStatus: {
    // 编辑页面信息
    method: "post",
    url: "web/page/cmsBusPageCon/modifyPageEffectiveStatus.json",
  },
  sortPage: {
    // 页面信息排序
    method: "post",
    url: "web/page/cmsBusPageCon/sortPage.json",
  },
  deletePageById: {
    // 删除页面信息
    method: "post",
    url: "web/page/cmsBusPageCon/removePage.json",
  },
  siteQueryListNew: {
    // 新站点列表查询
    method: "post",
    url: "web/module/cmsBusModuleCon/querySiteList.json",
  },
  // 
  siteQueryList: {
    // 老站点列表查询
    method: "post",
    url: "web/site/cmsBusSiteCon/queryList.json",
  },
  saveSiteQuery: {
    // 新增站点
    method: "post",
    url: "web/site/cmsBusSiteCon/saveSite.json",
  },
  modifySiteName: {
    //修改站点
    method: "post",
    url: "web/site/cmsBusSiteCon/modifySiteName.json",
  },

  queryHotWordList: {
    // 热词搜索 查询 table数据
    method: "post",
    url: "/web/search/word/queryHotWordList.json"
  },
  addHotWord: {
    // 热词搜索  新增
    method: "post",
    url: "/web/search/word/addHotWord.json",
    config: {
      btnLoading: true,
    }
  },
  updateHotWord: {
    // 热词搜索 修改
    method: "post",
    url: "/web/search/word/updateHotWord.json",
    config: {
      btnLoading: true,
    }
  },
  deleteHotWord: {
    // 热词搜索  删除
    method: "post",
    url: "/web/search/word/deleteHotWord.json"
  },
  queryHotWordDetail: {
    // 热词搜索  查询 form数据
    method: "post",
    url: "/web/search/word/queryHotWordDetail.json"
  },
  uploadImage: {
    // CMS首页内容管理，上传图片接口
    method: "post",
    url: "/web/upload/cmsBusUploadCon/uploadImage.json"
  },
  secondKillQueryList: {
    // 秒杀频道 查询列表
    method: "post",
    url: "/web/seckill/cmsBusSeckillCon/queryList.json"
  },
  secondKillQueryDetail: {
    // 秒杀频道 查询详情
    method: "post",
    url: "/web/seckill/cmsBusSeckillCon/queryDetail.json"
  },
  secondKillSaveMallRound: {
    // 秒杀频道 新增
    method: "post",
    url: "/web/seckill/cmsBusSeckillCon/saveMallRound.json",
    config: {
      btnLoading: true,
    }
  },
  secondKillRemoveMallRound: {
    // 秒杀频道 删除
    method: "post",
    url: "/web/seckill/cmsBusSeckillCon/removeMallRound.json"
  },
  secondKillModifyMallRound: {
    // 秒杀频道 修改
    method: "post",
    url: "/web/seckill/cmsBusSeckillCon/modifyMallRound.json",
    config: {
      btnLoading: true,
    }
  },
  secondKillQueryActivity: {
    // 秒杀频道 查询关联活动号
    method: "post",
    url: "/web/seckill/cmsBusSeckillCon/queryActivity.json"
  },
  secondKillQueryGoods: {
    // 秒杀频道 查询橱窗商品
    method: "post",
    url: "/web/seckill/cmsBusSeckillCon/queryGoods.json"
  },
};

export default { ...cms, ...activity, ...settlementCenter, ...lockPrice, ...refundRule, ...show, ...marketingAgency, ...auditList, pricedIscount, ...coupon, ...reduction, ...seckill, ...content, ...discount, ...manage, ...postpaymentList, ...apiError};
