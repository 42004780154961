export default {
    /**
     * 优惠券列表
     */

    // 文件上传
    discountUploadSignedFile: {
        method: 'post',
        url: 'web/discount/uploadSignedFile.json'
    },
    // 预付出资金额
    discountQueryInvestDueRate: {
        method: 'post',
        url: 'web/discount/queryInvestDueRate.json'
    }, 
    // 页面查询列表
    discountQueryMarketingList: {
        method: 'post',
        url: 'web/discount/queryMarketingList.json'
    }, 
    // 添加活动
    discountAddMarketing: {
        method: 'post',
        url: 'web/discount/addMarketing.json',
        config: {
            btnLoading: true,
        }
    },
    // 查看活动商品详情
    discountQueryMarketingGoodsInfo: {
        method: 'post',
        url: 'web/discount/queryMarketingGoodsInfo.json'
    },
    // 查看活动信息
    discountQueryMarketingInfo: {
        method: 'post',
        url: 'web/discount/queryMarketingInfo.json'
    },
    // 更新活动
    discountUpdateMarketing: {
        method: 'post',
        url: 'web/discount/updateMarketing.json',
        config: {
            btnLoading: true,
        }
    },
    // 活动详情
    discountQueryMarketingDetail: {
        method: 'post',
        url: 'web/discount/queryMarketingDetail.json'
    },
    
    // 预付出资金额比列
    discountQueryInvestDueRate: {
        method: 'post',
        url: 'web/discount/queryInvestDueRate.json'
    },
    // 终止活动
    discountTerminateMarketing: {
        method: 'post',
        url: 'web/discount/terminateMarketing.json',
        config: {
            btnLoading: true,
        }
    }, 
    // 重新认款
    discountMarketingRecognize: {
        method: 'post',
        url: 'web/discount/marketingRecognize.json',
        config: {
            btnLoading: true,
        }
    },
    // 追加认款
    // discountMarketingRecognizeList
    discountMarketingRecognizeAppend: {
        method: 'post',
        url: 'web/discount/marketingRecognizeAppend.json',
        config: {
            btnLoading: true,
        }
    },
    // 认款列表
    discountQueryMarketingRecognizeList: {
        method: 'post',
        url: 'web/discount/queryMarketingRecognizeList.json'
    },
    // 取消修改
    discountCancelMarketing: {
        method: 'post',
        url: 'web/discount/cancelMarketing.json',
    },
    // 审核状态列表
    discountFindMarketingAuditList: {
        method: 'post',
        url: 'web/discount/findMarketingAuditList.json'
    },
    // 下载链接
    discountDownStream: {
        method: 'post',
        url: 'web/discount/downStream.json',
        responseType: 'blob',
    },
    // 成本分摊配置 查询接口
    discountQueryInvestor: {
        method: 'post',
        url: 'web/discount/queryInvestor.json'
    },
    // 成本分摊配置 查询出资方式、账户、机构
    discountQueryInvestInfo: {
        method: 'post',
        url: 'web/discount/queryInvestInfo.json'
    },
      /**
     * 审核详情商品列表
     */
    discountFindMarketingAuditGoodsList: {
        method: 'post',
        url: 'web/discount/findMarketingAuditGoodsList.json',
        config: {
            btnLoading: true,
        }
    },
    // 查看详情
    discountFindMarketingDetail: {
        method: 'post',
        url: 'web/discount/findMarketingAuditDetail.json'
    },
    // 查询所有商品信息
    discountPageSkuInfo: {
        method: 'post',
        url: '/web/discount/pageSkuInfo.json'
    }
}