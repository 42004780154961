import request from '#/utils/request'

let siteListApi = {
    queryProappPage: (data) => { //产品查询接口
        return request({
            url: '/web/tm/Proapp/queryProappPage.json',
            method: 'get',
            params: data
        })
    },
    queryPfsMmodelPage: (data) => { //模板查询接口
        return request({
            url: '/web/pfs/pfsmmodel/queryPfsMmodelPage.json',
            method: 'get',
            params: data
        })
    },
    querytginfo: (data) => { //模板查询接口
        return request({
            url: 'web/cms/tginfo/queryTginfoPage.json',
            method: 'get',
            params: data
        })
    },
    //查询所属客户的
    queryUserinfoPageCon: (data) => { //模板查询接口
        return request({
            url: '/web/org/company/queryUserinfoPageCon.json',
            method: 'get',
            params: data
        })
    },
    // queryUserinfoPageCon: (data) => { //模板查询接口
    //     return request({
    //         url: '/web/org/company/queryUserinfoPageCon.json',
    //         method: 'get',
    //         params: data
    //     })
    // },
    queryCompanyPage: (data) => { //模板查询接口
        return request({
            url: '/web/org/company/queryCompanyPage.json',
            method: 'get',
            params: data
        })
    },
    //查询站点类型
    queryProappEnvPageByPTJS: (data) => { //模板查询接口
        return request({
            url: '/web/tm/Proapp/queryProappEnvPageByPTJS.json',
            method: 'get',
            params: data
        })
    },
    //添加站点
    saveCmsTginfo: (data) => { //模板查询接口
        return request({
            url: '/web/cms/tginfo/saveCmsTginfo.json',
            method: 'get',
            params: data
        })
    },
    //编辑站点
    updateTginfoPlus: (data) => { //模板查询接口
        return request({
            url: '/web/cms/tginfo/updateTginfoPlus.json',
            method: 'get',
            params: data
        })
    },
    //查询站点
    getTginfo: (data) => { //模板查询接口
        return request({
            url: '/web/cms/tginfo/getTginfo.json',
            method: 'get',
            params: data
        })
    },
}

export default siteListApi;