export default {
    // 结算单批量查询
    querySettlementList: {
        method: "post",
        url: "/web/merchant/settlement/querySettlementList.json",
        type: 'json',
    },
    // 修改结算清单
    updateSettlementList: {
        method: "post",
        url: "/web/merchant/settlement/updateSettlementList.json",
        type: 'json',
    },
    // 结算清单导出
    downloadBatchSettlementExcel: {
        method: "post",
        url: "/web/merchant/settlement/downloadBatchSettlementExcel.json",
        type: 'json',
    }
}
