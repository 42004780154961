export default {
    queryChannelProductList: {
        method: "post",
        url: "/web/channel/product/queryChannelProductList.json",
        type: 'json',

    },
    updateChannelProductList: { //修改
        method: "post",
        url: "web/channel/product/updateChannelProductList.json",
        // type: 'x-www-form',
    },
    queryChannelMerchantList: { //渠道商户结算列表搜索
        method: "post",
        url: "web/merchant/settlement/queryChannelMerchantList.json",
        // type: 'x-www-form',
    },
    queryChannelInfoList: { //渠道信息查询
        method: "post",
        url: "web/channel/product/queryChannelInfoList.json",
        // type: 'x-www-form',
    },
    queryChannelDetailInfoList: { //渠道详情信息 
        method: "post",
        url: "web/channel/product/queryChannelDetailInfoList.json",
        // type: 'x-www-form',
    },
    saveChannelMerchantList: { //渠道商户结算新增 
        method: "post",
        url: "web/merchant/settlement/saveChannelMerchantList.json",
        // type: 'x-www-form',
    },
    updateChannelMerchantList: { //渠道商户结算编辑 
        method: "post",
        url: "web/merchant/settlement/updateChannelMerchantList.json",
        // type: 'x-www-form',
    },
    batchChannelGoodsUpAndDown: { //渠道商品修改状态
        method: "post",
        url: "/web/channel/product/batchChannelGoodsUpAndDown.json",
        // type: 'x-www-form',
    },
}
