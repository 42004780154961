import { getToken } from '../../utils/auth'
const car = {
  state: {
    carOrderInfor: getToken('carOrder'), // 购物车全选数组值
    brandList: [], // 商品品牌
    classList: [], // 商品分类
    address: [], // 地址数据 多店铺
    addressNum: '', // 多店铺中的第几个
    carList:[]
  },
  // getters: {
  //   address: state => state.address,
  //   addressNum: state => state.addressNum
  // },
  mutations: {
    carList(state, data){
      state.carList=data
      console.log('cefrvtgh',data)
    },
    CARORDER(state, data) {
      state.carOrderInfor = data
    },
    SET_BRANDLIST(state, data) {
      state.brandList = data
    },
    SET_CLASS_LIST(state, data) {
      state.classList = data
    },
    ADDRESS(state, data) {
      state.address = data
      console.log(state.address, '收货地址')
      // if (data.index > -1) {
      //   // data 是个对象
      //   state.address[data.index] = data
      // } else {
      //   // data 是个数组
      //   console.log(data, '收货地址');
      //   state.address[data.index] = data
      // }
    },
    CHANGEADDRESSNUM(state, data) {
      state.addressNum = data
    }
  },
  actions: {
    ADDRESS: ({
      commit
    }, params) => { }
  }
}
export default car
